<template>
  <vx-card title="กำหนดสถานะข้อมูล">
    <FormLayout>
      <FormLabel>
        <span>กำหนดสถานะโครงการ</span>
      </FormLabel>
      <FormContent>
        <ul class="leftx">
          <li>
            <vs-radio
              v-model="$attrs.value.project_status"
              vs-name="project_status"
              vs-value="new_project"
            >
              <div class="vx-row items-center">
                <div class="vx-col w-full">
                  <span>โครงการใหม่</span>
                </div>
              </div>
            </vs-radio>
          </li>
          <li>
            <div 
              v-if="$attrs.value.project_status === 'new_project'" 
              class="vx-row">
              <div class="vx-col w-4/12 ml-10">
                <span>วันสิ้นสุดสถานะโครงการใหม่</span>
              </div>
              <div class="vx-col w-5/12">
                <datepicker
                  v-model="$attrs.value.new_project_end_date"
                  :format="customFormatter"
                  :disabled-dates="minDate"
                />
                <!-- <datepicker
                  v-model="$attrs.value.new_project_end_date"
                  :format="customFormatter"
                /> -->
              </div>
            </div>
          </li>
          <li class="">
            <vs-radio
              v-model="$attrs.value.project_status"
              vs-name="project_status"
              vs-value="closed"
            >
              <div class="vx-row items-center">
                <div class="vx-col w-full">
                  <span>ปิดการขาย</span>
                </div>
              </div>
            </vs-radio>
          </li>
          <li class="">
            <vs-radio
              v-model="$attrs.value.project_status"
              vs-name="project_status"
              vs-value="not_specified"
            >
              <div class="vx-row items-center">
                <div class="vx-col w-full">
                  <span>ไม่ระบุ</span>
                </div>
              </div>
            </vs-radio>
          </li>
        </ul>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>URL Register</span>
      </FormLabel>
      <FormContent>
        <vs-input 
          v-model="$attrs.value.url_register" 
          class="w-full" />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="text-red">เพื่อส่งข้อมูลไปที่ระบบ FLM</span><br/>
        <span class="text-red">กรุณา </span><span>กำหนดวันแสดงผลงานบนเว็บไซต์</span>
      </FormLabel>
      <FormContent>
        <datepicker 
          class="w-6/12"
          v-model="$attrs.value.published_date" 
          clear-button
          :highlighted="{
            dates: [
              // Highlight an array of dates
              $attrs.value.published_date
                ? new Date($attrs.value.published_date)
                : new Date(),
            ],
          }"
          :disabled-dates="minDate"
          :format="customFormatter" /> 
      </FormContent>
    </FormLayout>
  </vx-card>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  components: {
    Datepicker,
  },
  methods: {
    customFormatter(date) {
      return moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
    },
  },
  computed: {
    minDate () {
      const min = moment().subtract(1, 'd').format()
        
      return { to: new Date(min) }
    }
  }
}
</script>

<style>
.vs-radio--input:checked + .vs-radio {
  cursor: pointer !important;
}
.text-red {
  color: red;
}
</style>
