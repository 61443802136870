<template>
  <div>
    <FormLayout>
      <FormLabel>
        <div class="flex md:justify-end space-x-2">
          <span>อาคารที่ {{ id }}</span>
          <span>ชื่อ อาคาร</span>
        </div>
      </FormLabel>
      <FormContent class="flex space-x-2">
        <div class="w-5/12">
          <vs-input 
            v-model="$attrs.value.building_name" 
            class="w-full" />
        </div>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        จำนวนชั้น
      </FormLabel>
      <FormContent class="flex items-center space-x-3">
        <lh-input-number
          v-model="$attrs.value.building_level" 
          class="w-3/12" />
        <span>ชั้น</span>
        <span class="md:w-5/12 xl:w-3/12 text-right">จำนวนห้อง</span>
        <lh-input-number
          v-model="$attrs.value.building_unit" 
          class="w-3/12" />
        <span>ยูนิต</span>
      </FormContent>
    </FormLayout>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 1,
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
.con-select {
  width: 100% !important;
}
</style>
