<template>
  <vx-card title="ข้อมูล VDO และ ภาพ 360 องศา">
    <FormLayout>
      <FormLabel>
        <span>เลือกชนิดการอัปไฟล์</span>
      </FormLabel>
      <FormContent>
        <ul>
          <li>
            <vs-radio
              v-model="$attrs.value.video_type"
              vs-name="video_type"
              vs-value="youtube"
            >
              <span>VDO Youtube</span>
            </vs-radio>
          </li>
          <li>
            <vs-radio
              v-model="$attrs.value.video_type"
              vs-name="video_type"
              vs-value="file"
            >
              <span>VDO File</span>
            </vs-radio>
          </li>
        </ul>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span
        >{{ thumbnailTitle }} <br >
          (.jpg , .png ,.gif)</span>
        <br>
        <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
        <br>
        <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          :name="thumbnailTitle"
          rules="lh_mimes:jpg,png,gif|lh_ratio:16,9"
        >
          <ImageUpload
            :folder="`${baseFolder}/VDO`"
            v-model="$attrs.value.video_thumbnail"
            :width="240"
            :has-delete-button="canDelete($attrs.value.video_thumbnail)"
            selection-name="video_thumbnail"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout v-show="$attrs.value.video_type === 'youtube'">
      <FormLabel>
        <span>URL Youtube</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="URL Youtube" 
          rules="url">
          <div>
            <vs-input
              v-model="$attrs.value.video_url"
              class="w-full"
              name="asset_value"
            />
          </div>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout v-show="$attrs.value.video_type === 'file'">
      <FormLabel>
        <span>Video (เฉพาะไฟล์ .mp4)</span><br>
        <span class="font-bold">ขนาดไฟล์ vdo ไม่เกิน 300 MB</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="Vdo File"
          rules="lh_size:300_MB"
        >
          <VdoUpload
            :folder="`${baseFolder}/VDO`"
            v-model="$attrs.value.video_file"
            :has-delete-button="canDelete($attrs.value.video_file)"
            selection-name="video-file"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <vs-divider />
    <FormLayout>
      <FormLabel>
        <span>360 Url</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="360 Url" 
          rules="path_url:false,false,false">
          <vs-input 
            v-model="$attrs.value.image_360_url" 
            class="w-full" />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span
        >360 Virtual Tour <br >
          (.jpg , .png ,.gif)</span
          >
        <br>
        <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
        <br>
        <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="360 Virtual Tour"
          rules="lh_mimes:jpg,png,gif|lh_ratio:16,9"
        >
          <ImageUpload
            :folder="`${baseFolder}/VDO`"
            v-model="$attrs.value.image_360_thumbnail"
            :width="240"
            :has-delete-button="canDelete($attrs.value.image_360_thumbnail)"
            selection-name="image_360_thumbnail"
          />
          <span
            v-show="errors.length > 0"
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
  </vx-card>
</template>

<script>
/* eslint-disable */
import ImageUpload from '@/components/upload/ImageUpload'
import VdoUpload from '@/components/upload/VdoUpload'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import env from '@/env'

export default {
  components: {
    ImageUpload,
    VdoUpload,
  },
  computed: {
    baseFolder() {
      const id = get(this.$attrs.value, 'id', null)
      return `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project/${id}`
    },
    thumbnailTitle() {
      return this.$attrs.value.video_type === 'file' ? 'Thumbnail VDO' : 'Thumbnail Youtube' 
    }
  },
  methods: {
    canDelete (files) {
      return !isEmpty(files)
    }
  }
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
</style>
