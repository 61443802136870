<template>
  <vx-card title="ข้อมูลที่อยู่อาศัย">
    <FormLayout 
      v-if="mode === 'edit'" 
      class="flex flex-col md:flex-row items-center">
      <FormLabel>
        <span>พื้นที่โครงการ</span>
      </FormLabel>
      <div class="vx-col flex items-center w-full md:w-2/12 xl:w-2/12 space-x-4 mb-6 md:mb-0">
        <div class="w-10/12 md:w-7/12">
          <lh-input-number
            v-model="$attrs.value.area_rai"
            class="w-full" />
        </div>
        <div class="w-2/12 md:w-2/12">
          <span>ไร่</span>
        </div>
      </div>
      <div class="vx-col flex items-center w-full md:w-2/12 md:w-2/12 xl:w-2/12  space-x-4 mb-6 md:mb-0">
        <div class="w-10/12 md:w-7/12">
          <lh-input-number
            v-model="$attrs.value.area_ngan"
            class="w-full" />
        </div>
        <div class="w-2/12 md:w-2/12">
          <span>งาน</span>
        </div>
      </div>
      <div class="vx-col flex items-center w-full md:w-4/12 xl:w-4/12 space-x-4 mb-6 md:mb-0">
        <div class="w-10/12 md:w-3/12">
          <lh-input-number
            v-model="$attrs.value.area_wa"
            class="w-full" />
        </div>
        <div class="w-2/12 md:w-6/12">
          <span>ตารางวา</span>
        </div>
      </div>
    </FormLayout>
    <FormLayout v-if="mode === 'edit'">
      <FormLabel>
        <span class="required">รหัสบริษัท(DL200)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="project-company-code"
          rules="required"
        >
          <vs-input
            v-model="$attrs.value.company_code"
            name="project-company-code"
            class="w-6/12 md:w-3/12 xl:w-3/12"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุรหัสบริษัท</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout v-if="mode === 'edit'">
      <FormLabel>
        <span class="required">รหัสโครงการ(DL200)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="project-code" 
          rules="required">
          <vs-input
            v-model="$attrs.value.project_code"
            name="project-code"
            class="w-6/12 md:w-3/12 xl:w-3/12"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุรหัสโครงการ</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout v-if="canDo">
      <FormLabel>
        <span class="required">ประเภทที่อยู่อาศัยที่เปิดขาย</span><br>
        <span>(ระบุได้มากกว่า 1 รายการ)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ validate, errors }"
          name="project-category"
          rules="required"
        >
          <div 
            v-for="(item, index) in categories" 
            :key="index" 
            class="vx-row">
            <div class="vx-col">
              <vs-checkbox
                v-if="item.code !== 'ladawan'"
                :name="`project-category-${index}`"
                v-model="$attrs.value.propertyTypes"
                :vs-value="item.id"
                class="w-full mb-2"
                @change="
                  (value) => {
                    onChangeCheckbox(value)
                    validate(value)
                  }
                "
              >{{ item.project_category_name }}</vs-checkbox
              >
            </div>
          </div>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาเลือกอย่างน้อย 1 รายการ</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout v-if="canDo">
      <FormLabel>
        <span class="required">หน้าที่จะแสดง</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ validate, errors }"
          name="project-page"
          rules="required"
        >
          <div 
            v-for="(item, index) in pages" 
            :key="index" 
            class="vx-row mb-2">
            <div class="vx-col">
              <vs-checkbox
                v-model="$attrs.value.pageLinks"
                :vs-value="item.id"
                name="project-page"
                class="w-full"
                @change="validate"
              >{{ item.page_name }}</vs-checkbox
              >
            </div>
          </div>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาเลือกอย่างน้อย 1 รายการ</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout v-if="hasCondo && mode === 'edit'">
      <FormLabel>
        <span>จำนวนห้อง</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <div class="vx-col w-3/12">
          <lh-input-number
            v-model="$attrs.value.room_unit"
            class="w-full" />
        </div>
        <div class="vx-col">
          <span>ยูนิต</span>
        </div>
      </FormContent>
    </FormLayout>
    <FormLayout v-if="hasCondo && mode === 'edit' && lang === 'cn'">
      <FormLabel>
        <span>Location "ย่าน"" หรือ "ทำเล"</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <div class="w-5/12">
          <vs-input 
            v-model="$attrs.value.neighborhood" 
            class="w-full" />
        </div>
      </FormContent>
    </FormLayout>
    <FormLayout v-if="hasHouse && mode === 'edit'">
      <FormLabel>
        <span>จำนวนแปลง</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <div class="vx-col w-3/12">
          <lh-input-number
            v-model="$attrs.value.plot"
            class="w-full" />
        </div>
        <div class="vx-col">
          <span>แปลง</span>
        </div>
      </FormContent>
    </FormLayout>
    <div v-if="mode === 'edit'">
      <div 
        v-for="(id, i) in $attrs.value.propertyTypes" 
        :key="i">
        <vs-divider v-if="getCategory(id).code !== 'ladawan'"/>
        <CondominiumSection
          v-if="getCategory(id).property_type === 'condominium'"
          v-model="$attrs.value.propertyList[i]"
          :title="getCategory(id).project_category_name"
          :category-id="id"
        />
        <HouseSection
          v-if="getCategory(id).property_type === 'house' && getCategory(id).code !== 'ladawan'"
          v-model="$attrs.value.propertyList[i]"
          :title="getCategory(id).project_category_name"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import CondominiumSection from './CondominiumSection.vue'
import HouseSection from './HouseSection.vue'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
// import every from 'lodash/every'
import remove from 'lodash/remove'

export default {
  components: {
    CondominiumSection,
    HouseSection,
  },
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    categories: {
      type: Array,
      default: () => {},
    },
  },
  computed: {
    pages() {
      const allPages = get(this.$store.state, ['page', 'pages'], [])
      return allPages.filter((page) => page.type === 'category')
    },
    hasCondo() {
      return some(
        this.$attrs.value.propertyTypes,
        (id) => this.getCategory(id).property_type === 'condominium',
      )
    },
    hasHouse() {
      return some(
        this.$attrs.value.propertyTypes,
        (id) => this.getCategory(id).property_type === 'house',
      )
    },
    canDo() {
      return this.$acl.check('editor')
    },
    lang() {
      return this.$store.state.locale.currentLang
    }
  },
  methods: {
    getCategory(id) {
      if (isEmpty(this.categories)) return {}

      return this.categories.find((cat) => cat.id === id)
    },
    onChangeCheckbox(value) {
      if (value.length < this.$attrs.value.propertyList.length) {
        remove(
          this.$attrs.value.propertyList,
          (property) => !value.includes(property.property_category_id),
        )
      } else {
        value.forEach((catId) => {
          if (
            !some(this.$attrs.value.propertyList, ['property_category_id', catId]) ||
            this.$attrs.value.propertyList.length === 0
          ) {
            const category = this.getCategory(catId)
            const initialData = {
              property_category_id: catId,
            }
            if (category.property_type === 'condominium') {
              initialData.project_property_units = [{}]
              initialData.project_property_buildings = [{}]
            }
            this.$attrs.value.propertyList.push(initialData)
          }
        })
      }
    },
  },
}
</script>
