<template>
  <vx-card
    title="Lead image โครงการ *"
    subtitle="ตำแหน่งภาพ Lead image (.JPG , .PNG)"
  >
    <div class="vx-row">
      <div class="vx-col w-full">
        <p class="mb-4">
          <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
          <br>
          <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
        </p>
        <ValidationProvider
          v-slot="{ errors, validate }"
          name="Lead Image"
          rules="required|lh_mimes:jpg,png|lh_ratio:16,9"
        >
          <GalleryForm
            v-model="$attrs.value.lead_image"
            :folder="`${baseFolder}/Lead Image`"
            name="project-lead-image"
            has-alt-text
            has-ex-url
            selection-name="project-lead-image-section"
            @change="validate"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </vx-card>
</template>

<script>
import get from 'lodash/get'
import GalleryForm from '@/components/GalleryForm.vue'
import env from '@/env'

export default {
  name: 'LeadImageSection',
  components: {
    GalleryForm,
  },
  computed: {
    baseFolder() {
      const id = get(this.$attrs.value, 'id', null)
      return `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project/${id}`
    },
  },
}
</script>
