<template>
  <div>
    <h5 class="mb-6">{{ title }}</h5>
    <FormLayout>
      <FormLabel>
        <span>พื้นที่ใช้สอยตั้งแต่</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <div class="vx-col w-3/12">
          <lh-input-number
            v-model="$attrs.value.living_space"
            class="w-full" />
        </div>
        <div class="w-3/12">
          <span>ตารางเมตร</span>
        </div>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>สร้างบนขนาดที่ดินตั้งแต่</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <div class="vx-col w-3/12">
          <lh-input-number
            v-model="$attrs.value.house_build_space"
            class="w-full" />
        </div>
        <div class="vx-col w-3/12">
          <span>ตารางวา</span>
        </div>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>มีแบบบ้านให้เลือก</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <div class="vx-col w-3/12">
          <lh-input-number
            v-model="$attrs.value.house_style"
            class="w-full" />
        </div>
        <div class="vx-col w-3/12">
          <span>แบบ</span>
        </div>
      </FormContent>
    </FormLayout>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
.con-select {
  width: 100% !important;
}
</style>
