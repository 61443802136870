<template>
  <div>
    <h5 class="mb-6">สิ่งอำนวยความสะดวกรอบโครงการที่ {{ id }}</h5>
    <FormLayout>
      <FormLabel>
        <span>ชื่อ</span>
      </FormLabel>
      <FormContent>
        <vs-input 
          v-model="$attrs.value.facility_name" 
          class="w-full" />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ระยะห่าง</span>
      </FormLabel>
      <FormContent class="flex space-x-4">
        <lh-input-number
          v-model="$attrs.value.facility_distance"
          class="w-4/12" />
        <v-select
          class="w-4/12"
          v-model="$attrs.value.facility_distance_unit"
          :options="units"
          label="title"
          :reduce="project => `${project.id}`"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          placeholder="เลือกหน่วย"
        />
        <!-- <vs-select
          v-model="$attrs.value.facility_distance_unit"
          class="selectExample"
          icon="arrow_downward"
          autocomplete
        >
          <vs-select-item
            v-for="(item, index) in units"
            :value="item.id"
            :text="item.title"
            :key="index"
          />
        </vs-select> -->
      </FormContent>
    </FormLayout>
  </div>
</template>

<script>
import VSelect from 'vue-select'

export default {
  props: {
    id: {
      type: Number,
      default: 1,
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VSelect,
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
.con-select {
  width: 100% !important;
}
</style>
