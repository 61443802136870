<template>
  <vx-card>
    <div 
      v-for="(n, i) in unitPlanNumber" 
      :key="i">
      <UnitPlanSubSection
        :id="n"
        :folder="`${baseFolder}/Unit Plan`"
        v-model="$attrs.value.unitPlanList[i]"
      />
      <FormLayout>
        <FormLabel />
        <FormContent class="flex space-x-4">
          <div v-if="unitPlanNumber === n">
            <vs-button 
              :disabled="!canAddUnitPlan" 
              @click="addUnitPlan"
            >Add</vs-button
            >
          </div>
          <div v-if="unitPlanNumber !== 1">
            <vs-button 
              class="vs-button-danger" 
              @click="deleteUnitPlan(i)">Delete</vs-button>
          </div>
        </FormContent>
      </FormLayout>
      <vs-divider v-if="unitPlanNumber !== n" />
    </div>
  </vx-card>
</template>

<script>
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import UnitPlanSubSection from './UnitPlanSubSection.vue'
import env from '@/env'

export default {
  components: {
    UnitPlanSubSection,
  },
  computed: {
    unitPlanNumber() {
      const number =
        this.$attrs.value.unitPlanList.length === 0
          ? 1
          : this.$attrs.value.unitPlanList.length
      return number
    },
    baseFolder() {
      const id = get(this.$attrs.value, 'id', null)
      return `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project/${id}`
    },
    canAddUnitPlan() {
      // if (isEmpty(this.$attrs.project_property_units)) return false
      const lastUnitPlan = this.$attrs.value.unitPlanList[this.unitPlanNumber - 1]
      return lastUnitPlan.unit_plan_name || lastUnitPlan.unit_plan_size || lastUnitPlan.unit_plan_detail || lastUnitPlan.unit_plan_price || !isEmpty(lastUnitPlan.unit_plan_image)
    },
  },
  methods: {
    addUnitPlan() {
      this.$attrs.value.unitPlanList.push({})
    },
    deleteUnitPlan(index) {
      this.$attrs.value.unitPlanList.splice(index, 1)
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 30px;
}
</style>
