var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{attrs:{"title":"ข้อมูล VDO และ ภาพ 360 องศา"}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เลือกชนิดการอัปไฟล์")])]),_c('FormContent',[_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-name":"video_type","vs-value":"youtube"},model:{value:(_vm.$attrs.value.video_type),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "video_type", $$v)},expression:"$attrs.value.video_type"}},[_c('span',[_vm._v("VDO Youtube")])])],1),_c('li',[_c('vs-radio',{attrs:{"vs-name":"video_type","vs-value":"file"},model:{value:(_vm.$attrs.value.video_type),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "video_type", $$v)},expression:"$attrs.value.video_type"}},[_c('span',[_vm._v("VDO File")])])],1)])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v(_vm._s(_vm.thumbnailTitle)+" "),_c('br'),_vm._v("\n        (.jpg , .png ,.gif)")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":_vm.thumbnailTitle,"rules":"lh_mimes:jpg,png,gif|lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/VDO"),"width":240,"has-delete-button":_vm.canDelete(_vm.$attrs.value.video_thumbnail),"selection-name":"video_thumbnail"},model:{value:(_vm.$attrs.value.video_thumbnail),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "video_thumbnail", $$v)},expression:"$attrs.value.video_thumbnail"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.$attrs.value.video_type === 'youtube'),expression:"$attrs.value.video_type === 'youtube'"}]},[_c('FormLabel',[_c('span',[_vm._v("URL Youtube")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"URL Youtube","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('vs-input',{staticClass:"w-full",attrs:{"name":"asset_value"},model:{value:(_vm.$attrs.value.video_url),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "video_url", $$v)},expression:"$attrs.value.video_url"}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.$attrs.value.video_type === 'file'),expression:"$attrs.value.video_type === 'file'"}]},[_c('FormLabel',[_c('span',[_vm._v("Video (เฉพาะไฟล์ .mp4)")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดไฟล์ vdo ไม่เกิน 300 MB")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Vdo File","rules":"lh_size:300_MB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VdoUpload',{attrs:{"folder":(_vm.baseFolder + "/VDO"),"has-delete-button":_vm.canDelete(_vm.$attrs.value.video_file),"selection-name":"video-file"},model:{value:(_vm.$attrs.value.video_file),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "video_file", $$v)},expression:"$attrs.value.video_file"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("360 Url")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"360 Url","rules":"path_url:false,false,false"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",model:{value:(_vm.$attrs.value.image_360_url),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "image_360_url", $$v)},expression:"$attrs.value.image_360_url"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("360 Virtual Tour "),_c('br'),_vm._v("\n        (.jpg , .png ,.gif)")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"360 Virtual Tour","rules":"lh_mimes:jpg,png,gif|lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/VDO"),"width":240,"has-delete-button":_vm.canDelete(_vm.$attrs.value.image_360_thumbnail),"selection-name":"image_360_thumbnail"},model:{value:(_vm.$attrs.value.image_360_thumbnail),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "image_360_thumbnail", $$v)},expression:"$attrs.value.image_360_thumbnail"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }