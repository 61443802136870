<template>
  <vx-card title="แอดมินสร้างข้อมูล Support SEO">
    <FormLayout>
      <FormLabel>
        <span>Title <i class="text-danger">*</i><br >(กรอกได้ไม่เกิน 160 ตัวอักษร)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="project-seo-title" 
          rules="required">
          <vs-input
            :disabled="!canDo"
            v-model="$attrs.value.meta_title"
            :maxlength="160"
            class="w-full"
            name="project-seo-title"
            placeholder="Title (กรอกได้ไม่เกิน 160 ตัวอักษร)"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณากรอก Title</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>Description <i class="text-danger">*</i><br >(กรอกได้ไม่เกิน 300 ตัวอักษร)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="project-seo-description"
          rules="required"
        >
          <vs-textarea
            :disabled="!canDo"
            :class="!canDo ? 'textarea-disabled' : ''"
            v-model="$attrs.value.meta_description"
            :maxlength="300"
            name="project-seo-description"
            cols="10" 
            rows="9"
            label="Description (กรอกได้ไม่เกิน 300 ตัวอักษร)"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณากรอก Description</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">keyword</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="SEO Keywords"
          rules="required"
        >
          <vs-textarea
            :disabled="!canDo"
            :class="!canDo ? 'textarea-disabled' : ''"
            v-model="$attrs.value.meta_keyword"
            name="project-seo-keyword"
            cols="10" 
            rows="9"
            label="ตัวอย่าง : บ้านเดี่ยว , บ้านใหม่ ,ทาว์โฮม"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณากรอก Keyword</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
  </vx-card>
</template>

<script>
import get from 'lodash/get'

export default {
  name: 'SeoSection',
  computed: {
    canDo() {
    
      const activeUser = this.$store.state.AppActiveUser
      const canEditSeo = get(activeUser, ['setting', 'admin', 'set_seo_project'], false)

      return this.$route.path === '/property-asset/project/create' || this.$acl.check('super_editor') || canEditSeo
    },
  }
}
</script>

<style lang="scss">
  .textarea-disabled {
    opacity: 0.5;
  }
</style>
