<template>
  <div>
    <h5 class="mb-6">ระบุข้อมูล Unit Plan (สำหรับคอนโด)</h5>
    <FormLayout>
      <FormLabel>
        <span>ชื่อ Unit</span>
      </FormLabel>
      <FormContent>
        <vs-input 
          v-model="$attrs.value.unit_plan_name" 
          class="w-full" />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ภาพ Unit plan</span>
      </FormLabel>
      <FormContent>
        <ImageUpload
          v-model="$attrs.value.unit_plan_image"
          :folder="folder"
          :selection-name="`unit_plan_image_${id}`"
          :width="240"
          :has-delete-button="canDelete($attrs.value.unit_plan_image)"
          has-alt-text
        />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ขนาด</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <vs-input 
          v-model="$attrs.value.unit_plan_size" 
          class="w-full" />
        <span class="w-3/12">ตารางเมตร</span>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>รายละเอียด</span>
      </FormLabel>
      <FormContent>
        <vs-textarea 
          v-model="$attrs.value.unit_plan_detail" 
          class="w-full" 
          cols="10" 
          rows="5" />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ราคาเริ่มต้น</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <lh-input-number
          v-model="$attrs.value.unit_plan_price" 
          class="w-full" />
        <span class="w-3/12">ต่อยูนิต</span>
      </FormContent>
    </FormLayout>
  </div>
</template>

<script>
import ImageUpload from '@/components/upload/ImageUpload.vue'
import isEmpty from 'lodash/isEmpty'
import env from '@/env'

export default {
  name: 'UnitPlanSubSection',
  components: {
    ImageUpload,
  },
  props: {
    id: {
      type: Number,
      default: 1,
    },
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project`,
    },
  },
  methods: {
    canDelete (files) {
      return !isEmpty(files)
    }
  }
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
</style>
