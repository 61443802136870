<template>
  <div class="flex flex-col">
    <div class="mb-3">
      <ul>
        <li 
          v-for="(item, index) in assets" 
          :key="index">
          <embed
            :key="item.public_id"
            v-if="item.format === 'pdf'"
            :src="`${cldUpload.imagePath.value}/${item.public_id}`"
            width="200px"
            height="300px"
          >
          <span v-else>{{ `${getFilename(item.public_id)}` }}</span>
        </li>
      </ul>
    </div>
    <div>
      <MediaWidgetInit
        :folder="folder"
        :multiple="multiple"
        :selection-name="selectionName"
        :resource-type="resourceType"
        :has-delete-button="hasDeleteButton && assets && assets.length > 0"
        @selected-value="cldUpload.onSelectedValue"
        @render="cldUpload.onRender"
        @on-delete="onDelete"
      >
        <slot>Select File</slot>
      </MediaWidgetInit>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api'
import last from 'lodash/last'
import MediaWidgetInit from '@/components/MediaWidgetInit'
import useCldUpload from '@/use/useCldUpload'
import env from '@/env'
import isEmpty from "lodash/isEmpty"

export default {
  name: 'FileUpload',
  components: { MediaWidgetInit },
  props: {
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/images/tvc`,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    resourceType: {
      type: String,
      default: 'all',
    },
    selectionName: {
      type: String,
      required: true,
    },
    hasDeleteButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const cldUpload = useCldUpload(ctx)
    const assets = computed(() => {
      // add index
      cldUpload.assets.value = !isEmpty(cldUpload.assets.value) ? cldUpload.assets.value.map((value, index) => {
        return {
          ...value,
          image_index: index
        }
      }) : cldUpload.assets.value

      // add index
      ctx.attrs.value = !isEmpty(ctx.attrs.value) ? ctx.attrs.value.map((value, index) => {
        return {
          ...value,
          image_index: index
        }
      }) : ctx.attrs.value

      return !isEmpty(cldUpload.assets.value) ? cldUpload.assets.value : ctx.attrs.value
    })

    const getFilename = (src) => {
      return src ? last(src.split('/')) : ''
    }

    const onDelete = () => {
      cldUpload.assets.value = []
      ctx.attrs.value = []
      ctx.emit('input', [])
    }

    onMounted(() => {
      ctx.attrs.value = !isEmpty(ctx.attrs.value) ? ctx.attrs.value.map((value, index) => {
        return {
          ...value,
          image_index: index
        }
      }) : ctx.attrs.value
    })

    return {
      cldUpload,
      assets,
      getFilename,
      onDelete
    }
  },
}
</script>

<style scoped></style>
