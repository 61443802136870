<template>
  <vx-card>
    <div 
      v-for="(n, i) in progressNumber" 
      :key="i">
      <ProgressSubSection
        :id="n"
        :folder="`${baseFolder}/Progress`"
        v-model="$attrs.value.progressList[i]"
      />
      <FormLayout>
        <FormLabel/>
        <FormContent class="flex space-x-4">
          <div v-if="progressNumber === n">
            <vs-button 
              :disabled="!canAddProgress" 
              @click="addProgress"
            >Add</vs-button
            >
          </div>
          <div v-if="progressNumber !== 1">
            <vs-button 
              class="vs-button-danger" 
              @click="deleteProgress(i)">Delete</vs-button>
          </div>
        </FormContent>
      </FormLayout>
      <vs-divider v-if="progressNumber !== n" />
    </div>
  </vx-card>
</template>

<script>
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import ProgressSubSection from './ProgressSubSection.vue'
import env from '@/env'

export default {
  components: {
    ProgressSubSection,
  },
  props: {
    lang: {
      type: String,
      default: 'th',
    },
    initialValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    progressNumber() {
      const number =
        this.$attrs.value.progressList.length === 0
          ? 1
          : this.$attrs.value.progressList.length
      return number
    },
    canAddProgress() {
      // if (isEmpty(this.$attrs.project_property_units)) return false
      const lastProgress = this.$attrs.value.progressList[this.progressNumber - 1]
      return lastProgress.progress_percent || lastProgress.progress_date || !isEmpty(lastProgress.projectPropertyProgressFiles)
    },
    baseFolder() {
      const id = get(this.$attrs.value, 'id', null)
      return `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project/${id}`
    },
  },
  methods: {
    addProgress() {
      this.$attrs.value.progressList.push({
        projectPropertyProgressFiles: [],
      })
      // this.$attrs.value = Object.assign({}, this.$attrs.value)
    },
    deleteProgress(index) {
      this.$attrs.value.progressList.splice(index, 1)
      // this.$attrs.value = Object.assign({}, this.$attrs.value)
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 30px;
}
</style>
