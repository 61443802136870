<template>
  <div>
    <FormLayout>
      <FormLabel>
        พื้นหลัง
        <br>(กำหนดสีพื้นหลัง Banner) <span class="text-danger">*</span>
      </FormLabel>
      <div class="vx-col w-full md:w-8/12 md:flex md:relative">
        <div class="w-1/2">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="
              $attrs.value.is_event && $attrs.value.event.event_type === 'text_image'
                ? 'required'
                : ''
            "
            name="สีพื้นหลัง"
          >
            <input
              ref="colorPicker"
              v-model="state.color"
              :style="{
                backgroundColor,
                color: textColor
              }"
              class="color-input vs-inputx vs-input--input normal"
              autocomplete="off"
              name="event_background_color"
            >
            <span 
              v-if="errors.length > 0" 
              class="text-danger text-sm">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>
      </div>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        รูปภาพ Activity สูงสุด 1 รูป <span class="text-danger">*</span>
        <br>
        <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
        <br>
        <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="
            $attrs.value.is_event && $attrs.value.event.event_type === 'text_image'
              ? 'required|lh_mimes:jpg,png,gif|lh_ratio:16,9'
              : 'lh_ratio:16,9'
          "
          name="รูปภาพ Activity"
        >
          <ImageUpload
            v-model="$attrs.value.event.event_activity_image"
            :folder="folder"
            :has-delete-button="canDelete($attrs.value.event.event_activity_image)"
            selection-name="thumbnail"
          />
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout class="flex flex-col">
      <FormLabel custom-class="vx-col w-full md:w-6/12 pt-2">
        เนื้อหา Activity
        <p class="text-warning">**Support การ Upload Video ขนาดไม่เกิน 98MB</p>
      </FormLabel>
      <div class="vx-col w-full">
        <div class="content-editor">
          <!-- <quill-editor
            v-model="$attrs.value.event.event_activity_content"
            class="editor"
          /> -->
          <Wyswyg                     
            :folder="`${folder}/wyswyg`"
            v-model="$attrs.value.event.event_activity_content" />
        </div>
      </div>
    </FormLayout>
    <BannerDatepickerForm
      class="mt-6"
      v-model="$attrs.value" 
      event-type="text_image" />
  </div>
</template>

<script>
import 'huebee/dist/huebee.min.css'
/* eslint-disable-next-line */
import { ref, reactive, watch, computed, onMounted } from '@vue/composition-api'
import ImageUpload from '@/components/upload/ImageUpload'
import BannerDatepickerForm from '@/views/apps/banner/event/BannerDatepickerForm'
import Huebee from 'huebee'
import isEmpty from 'lodash/isEmpty'
import env from '@/env'
import Wyswyg from '@/components/Wyswyg'

export default {
  name: 'BannerEventPictureTextForm',
  components: {
    BannerDatepickerForm,
    ImageUpload,
    Wyswyg
  },
  props: {
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/banner`,
    },
  },
  /* eslint-disable-next-line */
  setup(props, ctx) {
    const state = reactive({
      color: '',
    })
    const huebee = ref(null)

    watch(
      () => ctx.attrs.value.event.event_background_color.hex,
      (newValue) => {
        state.color = newValue
      },
    )

    onMounted(() => {
      huebee.value = new Huebee('.color-input', {
        // options
        notation: 'hex',
        saturations: 2,
        shades: 7,
        hue0: 210
      })

      huebee.value.on('change', function(color) {
        ctx.attrs.value.event.event_background_color = {
          hex: color,
          text_color: ctx.refs.colorPicker.style.color
        }
      })
    })

    const backgroundColor = computed(() => {
      return ctx.attrs.value.event.event_background_color.hex
    })

    const textColor = computed(() => {
      return ctx.attrs.value.event.event_background_color.text_color
    })

    const canDelete = (files) => {
      return !isEmpty(files)
    }

    return {
      state,
      backgroundColor,
      textColor,
      canDelete
    }
  },
}
</script>

<style scoped>
.content-editor {
  height: 22rem;
}
.editor {
  height: 15rem;
}
</style>
