<template>
  <FormLayout>
    <FormLabel>
      <span>
        ระบุช่วงเวลา <span class="text-danger">*</span>
      </span>
    </FormLabel>
    <FormContent>
      <div class="flex flex-col md:flex-row md:-mx-2">
        <div class="w-full mb-2 md:w-1/2 md:px-2">
          <ValidationProvider
            v-slot="{ errors }"
            :vid="name + '-event-start-date'"
            :rules="
              $attrs.value.is_event && $attrs.value.event.event_type === eventType
                ? 'required'
                : ''
            "
            name="ช่วงเวลาเริ่มต้น"
          >
            <Datepicker
              v-model="$attrs.value.event_start_date"
              :highlighted="{
                dates: [
                  // Highlight an array of dates
                  $attrs.value.event_start_date
                    ? new Date($attrs.value.event_start_date)
                    : new Date(),
                ],
              }"
              :disabled-dates="$attrs.value.event_end_date ? {
                from: new Date($attrs.value.event_end_date)
              } : {}"
              format="dd/MM/yyyy"
              placeholder="ช่วงเวลาเริ่มต้น"
            />
            <span 
              v-if="errors.length > 0" 
              class="text-danger text-sm">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>
        <div class="w-full md:w-1/2 md:px-2">
          <ValidationProvider
            v-slot="{ errors }"
            :vid="name + '-event-end-date'"
            :rules="
              $attrs.value.is_event && $attrs.value.event.event_type === eventType
                ? 'required'
                : ''
            "
            name="ช่วงเวลาสิ้นสุด"
          >
            <Datepicker
              v-model="$attrs.value.event_end_date"
              :highlighted="{
                dates: [
                  // Highlight an array of dates
                  $attrs.value.event_end_date
                    ? new Date($attrs.value.event_end_date)
                    : new Date(),
                ],
              }"
              :disabled-dates="$attrs.value.event_start_date ? {
                to: new Date($attrs.value.event_start_date)
              } : {}"
              format="dd/MM/yyyy"
              placeholder="ช่วงเวลาสิ้นสุด"
            />
            <span 
              v-if="errors.length > 0" 
              class="text-danger text-sm">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>
      </div>
    </FormContent>
  </FormLayout>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import useDatepicker from '@/use/useDatepicker'
export default {
  name: 'BannerDatepickerForm',
  components: { Datepicker },
  props: {
    name: {
      type: String,
      default: 'banner-event',
    },
    eventType: {
      type: String,
      required: true,
    },
  },
  setup(props, { attrs }) {
    const { moment } = useDatepicker()

    const disabledDateLessThanStartDate = (date) => {
      return moment(date).isBefore(attrs.value.event_start_date)
    }

    return {
      disabledDateLessThanStartDate,
    }
  },
}
</script>
