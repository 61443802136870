<template>
  <div>
    <FormLayout>
      <FormLabel>
        <span>เลือกชนิดการอัพโหลด</span>
      </FormLabel>
      <FormContent>
        <ul>
          <li>
            <vs-radio
              v-model="$attrs.value.event.event_video_type"
              vs-name="event_video_type"
              vs-value="video_youtube"
            >
              VDO Youtube
            </vs-radio>
          </li>
          <li>
            <vs-radio
              v-model="$attrs.value.event.event_video_type"
              vs-name="event_video_type"
              vs-value="video_uploaded"
            >
              VDO File
            </vs-radio>
          </li>
        </ul>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">Thumbnail(.jpg , .png , .gif)</span>
        <br>
        <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
        <br>
        <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          :vid="'thumbnail'"
          :rules="
            $attrs.value.is_event && $attrs.value.event.event_type === 'video'
              ? 'required|lh_mimes:jpg,png,gif|lh_ratio:16,9'
              : 'lh_ratio:16,9'
          "
          name="Thumbnail"
        >
          <ImageUpload
            :folder="folder"
            v-model="$attrs.value.event.event_video_thumbnail"
            :has-delete-button="canDelete($attrs.value.event.event_video_thumbnail)"
            selection-name="event_video_thumbnail"
          />
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout v-show="$attrs.value.event.event_video_type === 'video_youtube'">
      <FormLabel>
        <span class="required">URL Youtube</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          :vid="'project-url-youtube'"
          :rules="
            $attrs.value.is_event &&
              $attrs.value.event.event_type === 'video' &&
              $attrs.value.event.event_video_type === 'video_youtube'
              ? 'required|url'
              : ''
          "
          name="URL Youtube"
        >
          <vs-input
            v-model="$attrs.value.event.event_video_url"
            class="w-full"
            placeholder="URL Youtube"
            name="asset_value"
          />
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">{{
              errors[0]
            }}</span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout v-show="$attrs.value.event.event_video_type === 'video_uploaded'">
      <FormLabel>
        <span>Video (เฉพาะไฟล์ .mp4)*</span><br>
        <span class="font-bold">ขนาดไฟล์ vdo ไม่เกิน 300 MB</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          :vid="'video'"
          :rules="
            $attrs.value.is_event &&
              $attrs.value.event.event_type === 'video' &&
              $attrs.value.event.event_video_type === 'video_uploaded'
              ? 'required|lh_size:300_MB'
              : 'lh_size:300_MB'
          "
          name="Video"
        >
          <VdoUpload
            :folder="folder"
            v-model="$attrs.value.event.event_video_file"
            :has-delete-button="canDelete($attrs.value.event.event_video_file)"
            selection-name="event-video-file"
          />
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <BannerDatepickerForm 
      v-model="$attrs.value" 
      name="banner-video" 
      event-type="video" />
  </div>
</template>

<script>
import VdoUpload from '@/components/upload/VdoUpload'
import ImageUpload from '@/components/upload/ImageUpload'
import BannerDatepickerForm from '@/views/apps/banner/event/BannerDatepickerForm'
import isEmpty from 'lodash/isEmpty'
import env from '@/env'

export default {
  name: 'BannerEventVideoForm',
  components: { BannerDatepickerForm, ImageUpload, VdoUpload },
  props: {
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/banner`,
    },
  },
  methods: {
    canDelete (files) {
      return !isEmpty(files)
    }
  }
}
</script>

<style scoped></style>
