<template>
  <vx-card title="ข้อมูลภาพ/แผนที่/ไฟล์ดาวน์โหลด (Admin ดำเนินการ)">
    <FormLayout>
      <FormLabel>
        <span
        >แผนที่โครงการ (.jpg,.png,.gif ขนาดรูปแนะนำ 1920 x 1080 px)</span
        >
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors, validate }"
          name="แผนที่โครงการ"
          rules="lh_required_cld|lh_mimes:jpg,png,gif"
        >
          <ImageUpload
            v-model="$attrs.value.map_img"
            :folder="`${baseFolder}/แผนที่`"
            :width="240"
            :height="135"
            :has-delete-button="canDelete($attrs.value.map_img)"
            selection-name="map_img"
            @change="validate"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <vs-divider />
    <FormLayout>
      <FormLabel>
        <span>แผนที่โครงการ (.PDF)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors, validate }"
          name="แผนที่โครงการ (.PDF)"
          rules="lh_required_cld|lh_mimes:pdf"
        >
          <FileUpload
            v-model="$attrs.value.map_file"
            :folder="`${baseFolder}/แผนที่`"
            :has-delete-button="canDelete($attrs.value.map_file)"
            selection-name="map_file"
            @change="validate"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <vs-divider />
    <FormLayout>
      <FormLabel>
        <span class="required">LOGO โครงการ * (.PNG ขนาดรูปไม่เกิน 150 x 150 px)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="LOGO โครงการ"
          rules="required|lh_mimes:png|lh_image_max_resolution:150,150"
        >
          <ImageUpload
            v-model="$attrs.value.logo"
            class="w-8/12"
            :folder="`${baseFolder}/Logo`"
            :has-delete-button="canDelete($attrs.value.logo)"
            :width="0"
            name="project-logo"
            selection-name="logo"
            has-alt-text
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <vs-divider />
    <FormLayout>
      <FormLabel>
        <span>Download Brochure (.pdf)</span>
      </FormLabel>
      <FormContent>
        <FileUpload
          v-model="$attrs.value.brochure"
          :folder="`${baseFolder}/Brochure`"
          :has-delete-button="canDelete($attrs.value.brochure)"
          selection-name="brochure"
        />
      </FormContent>
    </FormLayout>
  </vx-card>
</template>

<script>
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import ImageUpload from '@/components/upload/ImageUpload.vue'
import FileUpload from '@/components/upload/FileUpload.vue'
import env from '@/env'

export default {
  name: 'MapSection',
  components: {
    ImageUpload,
    FileUpload,
  },
  computed: {
    baseFolder() {
      const id = get(this.$attrs.value, 'id', null)
      return `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project/${id}`
    },
  },
  methods: {
    onSelectedValue(assets) {
      this.assets = assets
    },
    canDelete (files) {
      return !isEmpty(files)
    }
  },
}
</script>

<style lang="scss">
  div[name="project-logo"] {
    img {
      margin-top: 0.8rem;
      margin-bottom: 1rem;
    }
  }
</style>
