var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เลือกชนิดการอัพโหลด")])]),_c('FormContent',[_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-name":"event_video_type","vs-value":"video_youtube"},model:{value:(_vm.$attrs.value.event.event_video_type),callback:function ($$v) {_vm.$set(_vm.$attrs.value.event, "event_video_type", $$v)},expression:"$attrs.value.event.event_video_type"}},[_vm._v("\n            VDO Youtube\n          ")])],1),_c('li',[_c('vs-radio',{attrs:{"vs-name":"event_video_type","vs-value":"video_uploaded"},model:{value:(_vm.$attrs.value.event.event_video_type),callback:function ($$v) {_vm.$set(_vm.$attrs.value.event, "event_video_type", $$v)},expression:"$attrs.value.event.event_video_type"}},[_vm._v("\n            VDO File\n          ")])],1)])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("Thumbnail(.jpg , .png , .gif)")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"vid":'thumbnail',"rules":_vm.$attrs.value.is_event && _vm.$attrs.value.event.event_type === 'video'
            ? 'required|lh_mimes:jpg,png,gif|lh_ratio:16,9'
            : 'lh_ratio:16,9',"name":"Thumbnail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":_vm.folder,"has-delete-button":_vm.canDelete(_vm.$attrs.value.event.event_video_thumbnail),"selection-name":"event_video_thumbnail"},model:{value:(_vm.$attrs.value.event.event_video_thumbnail),callback:function ($$v) {_vm.$set(_vm.$attrs.value.event, "event_video_thumbnail", $$v)},expression:"$attrs.value.event.event_video_thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")]):_vm._e()]}}])})],1)],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.$attrs.value.event.event_video_type === 'video_youtube'),expression:"$attrs.value.event.event_video_type === 'video_youtube'"}]},[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("URL Youtube")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"vid":'project-url-youtube',"rules":_vm.$attrs.value.is_event &&
            _vm.$attrs.value.event.event_type === 'video' &&
            _vm.$attrs.value.event.event_video_type === 'video_youtube'
            ? 'required|url'
            : '',"name":"URL Youtube"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"URL Youtube","name":"asset_value"},model:{value:(_vm.$attrs.value.event.event_video_url),callback:function ($$v) {_vm.$set(_vm.$attrs.value.event, "event_video_url", $$v)},expression:"$attrs.value.event.event_video_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.$attrs.value.event.event_video_type === 'video_uploaded'),expression:"$attrs.value.event.event_video_type === 'video_uploaded'"}]},[_c('FormLabel',[_c('span',[_vm._v("Video (เฉพาะไฟล์ .mp4)*")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดไฟล์ vdo ไม่เกิน 300 MB")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"vid":'video',"rules":_vm.$attrs.value.is_event &&
            _vm.$attrs.value.event.event_type === 'video' &&
            _vm.$attrs.value.event.event_video_type === 'video_uploaded'
            ? 'required|lh_size:300_MB'
            : 'lh_size:300_MB',"name":"Video"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VdoUpload',{attrs:{"folder":_vm.folder,"has-delete-button":_vm.canDelete(_vm.$attrs.value.event.event_video_file),"selection-name":"event-video-file"},model:{value:(_vm.$attrs.value.event.event_video_file),callback:function ($$v) {_vm.$set(_vm.$attrs.value.event, "event_video_file", $$v)},expression:"$attrs.value.event.event_video_file"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")]):_vm._e()]}}])})],1)],1),_c('BannerDatepickerForm',{attrs:{"name":"banner-video","event-type":"video"},model:{value:(_vm.$attrs.value),callback:function ($$v) {_vm.$set(_vm.$attrs, "value", $$v)},expression:"$attrs.value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }