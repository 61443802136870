<template>
  <vx-card title="ระบุราคาขายโครงการ (เลือกรูปแบบอย่างใดอย่างหนึ่ง)">
    <FormLayout>
      <FormLabel>
        <span>ระบุราคาขายโครงการ</span>
      </FormLabel>
      <div class="vx-col w-full md:w-9/12">
        <ul class="leftx">
          <li class="mt-3 vx-row">
            <vs-radio
              v-model="$attrs.value.price_type"
              vs-name="radios1"
              vs-value="no_price"
            >
              <div class="vx-row flex space-x-4 items-center">
                <div class="ml-4">
                  <span>ไม่แสดงราคาบนหน้าเว็บไซค์</span>
                </div>
              </div>
            </vs-radio>
          </li>
          <li class="mt-6 vx-row">
            <vs-radio
              v-model="$attrs.value.price_type"
              vs-name="radios1"
              vs-value="price"
            >
              <ValidationProvider
                v-slot="{ errors, validate }"
                :rules="$attrs.value.price_type === 'price' ? 'required|price' : ''"
                name="ราคา"
              >
                <div class="vx-row flex space-x-4 items-center">
                  <div class="ml-4">
                    <span 
                      :class="$attrs.value.price_type === 'price' ? 'required' : ''"
                    >ราคาเริ่มต้น</span
                    >
                  </div>
                  <div class="h-16 mt-1">
                    <lh-input-number
                      v-model="$attrs.value.price"
                      name="project-price"
                      class="w-full"
                      @change="validate"
                    />
                    <span 
                      v-show="errors.length > 0" 
                      class="text-danger text-sm"
                    >{{ errors[0] }}</span
                    >
                  </div>
                  <div class="pt-3">
                    <span>บาท (เช่น 3,000,000 บาท เป็นต้น)</span>
                  </div>
                </div>
              </ValidationProvider>
            </vs-radio>
          </li>
          <li class="mt-6 vx-row w-full">
            <vs-radio
              v-model="$attrs.value.price_type"
              class="w-full"
              vs-name="radios1"
              vs-value="price_range"
            >
              <div class="vx-row items-center">
                <div class="vx-col">
                  <span
                    :class="$attrs.value.price_type === 'price_range' ? 'required' : ''"
                  >ราคา</span
                  >
                </div>
                <div class="vx-col w-4/12 md:w-2/12 pl-0 pr-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="$attrs.value.price_type === 'price_range' ? 'required|price' : ''"
                    name="ราคา"
                  >
                    <lh-input-number
                      v-model="$attrs.value.price_from"
                      class="w-full"
                      name="project-price-from"
                    />
                    <span 
                      v-show="errors.length > 0" 
                      class="text-danger text-sm"
                    >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="vx-col">
                  <span>-</span>
                </div>
                <div class="vx-col w-4/12 md:w-2/12 pl-0 pr-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="$attrs.value.price_type === 'price_range' ? 'required|price' : ''"
                    name="ราคา"
                  >
                    <lh-input-number
                      v-model="$attrs.value.price_to"
                      class="w-full"
                      name="project-price-to"
                    />
                    <span 
                      v-show="errors.length > 0" 
                      class="text-danger text-sm"
                    >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="vx-col pt-3">
                  <span>บาท (เช่น 3,000,000 - 6,000,000 บาท เป็นต้น)</span>
                </div>
              </div>
            </vs-radio>
          </li>
          <li class="mt-6 vx-row w-full">
            <vs-radio
              v-model="$attrs.value.price_type"
              :disabled="true"
              class="w-full"
              vs-name="radios1"
              vs-value="price_per_metre"
            >
              <div class="vx-row items-center">
                <div class="vx-col">
                  <span
                    :class="
                      $attrs.value.price_type === 'price_per_metre' ? 'required' : ''
                    "
                  >ราคา</span
                  >
                </div>
                <div class="vx-col w-2/12 pl-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="
                      $attrs.value.price_type === 'price_per_metre' ? 'required|price' : ''
                    "
                    name="ราคา"
                  >
                    <lh-input-number
                      v-model="$attrs.value.price_per_metre"
                      class="w-full"
                      name="project-price-per-metre"
                    />
                    <span 
                      v-show="errors.length > 0" 
                      class="text-danger text-sm"
                    >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="vx-col w-6/12 pt-3">
                  <span>บาท/ตารางเมตร (เช่น 120,000 บาท/ตารางเมตร เป็นต้น)</span>
                </div>
              </div>
            </vs-radio>
          </li>
        </ul>
      </div>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>แนวคิดโครงการ</span><br>
        <span class="text-warning text-sm">กรอกได้ไม่เกิน 200 ตัวอักษร</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors, validate }"
          rules="max:200"
          name="แนวคิดโครงการ"
        >
          <vs-textarea 
            v-model="$attrs.value.concept" 
            class="w-full" 
            cols="10" 
            rows="9"
            @change="validate"
            :maxlength="200"
          />
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">{{ errors[0] }}</span>
        </ValidationProvider>       
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>จุดเด่นของโครงการ</span>
      </FormLabel>
      <FormContent>
        <vs-textarea 
          v-model="$attrs.value.highlight" 
          class="w-full" 
          cols="10" 
          rows="9" />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>สิ่งอำนวยความสะดวก</span>
      </FormLabel>
      <FormContent>
        <vs-textarea 
          v-model="$attrs.value.facility" 
          class="w-full" 
          cols="10" 
          rows="9" />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ระบบรักษาความปลอดภัย</span>
      </FormLabel>
      <FormContent>
        <vs-textarea 
          v-model="$attrs.value.security" 
          class="w-full" 
          cols="10" 
          rows="9" />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ข้อมูล สคบ.</span>
      </FormLabel>
      <FormContent>
        <vs-textarea 
          v-model="$attrs.value.ocpb" 
          class="w-full" 
          cols="10" 
          rows="9" />
      </FormContent>
    </FormLayout>
    <FormLayout v-if="isCondo">
      <FormLabel>
        <span>ข้อมูล สถานที่ตั้ง</span>
      </FormLabel>
      <FormContent>
        <vs-textarea 
          v-model="$attrs.value.location" 
          class="w-full" 
          cols="10" 
          rows="9" />
      </FormContent>
    </FormLayout>
    <FormLayout v-if="isCondo">
      <FormLabel>
        <span>ข้อมูล ที่จอดรถ</span>
      </FormLabel>
      <FormContent>
        <vs-textarea 
          v-model="$attrs.value.parking" 
          class="w-full" 
          cols="10" 
          rows="9" />
      </FormContent>
    </FormLayout>
  </vx-card>
</template>

<script>
export default {
  name: 'InfoSection',
  props: {
    isCondo: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  .price-range-radio {
    margin-top: -1.7rem;
  }
  .vs-radio--label {
    width: 100%;
  }
</style>
