var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("\n      ระบุช่วงเวลา "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('FormContent',[_c('div',{staticClass:"flex flex-col md:flex-row md:-mx-2"},[_c('div',{staticClass:"w-full mb-2 md:w-1/2 md:px-2"},[_c('ValidationProvider',{attrs:{"vid":_vm.name + '-event-start-date',"rules":_vm.$attrs.value.is_event && _vm.$attrs.value.event.event_type === _vm.eventType
              ? 'required'
              : '',"name":"ช่วงเวลาเริ่มต้น"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('Datepicker',{attrs:{"highlighted":{
              dates: [
                // Highlight an array of dates
                _vm.$attrs.value.event_start_date
                  ? new Date(_vm.$attrs.value.event_start_date)
                  : new Date() ],
            },"disabled-dates":_vm.$attrs.value.event_end_date ? {
              from: new Date(_vm.$attrs.value.event_end_date)
            } : {},"format":"dd/MM/yyyy","placeholder":"ช่วงเวลาเริ่มต้น"},model:{value:(_vm.$attrs.value.event_start_date),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "event_start_date", $$v)},expression:"$attrs.value.event_start_date"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n            "+_vm._s(errors[0])+"\n          ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"w-full md:w-1/2 md:px-2"},[_c('ValidationProvider',{attrs:{"vid":_vm.name + '-event-end-date',"rules":_vm.$attrs.value.is_event && _vm.$attrs.value.event.event_type === _vm.eventType
              ? 'required'
              : '',"name":"ช่วงเวลาสิ้นสุด"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('Datepicker',{attrs:{"highlighted":{
              dates: [
                // Highlight an array of dates
                _vm.$attrs.value.event_end_date
                  ? new Date(_vm.$attrs.value.event_end_date)
                  : new Date() ],
            },"disabled-dates":_vm.$attrs.value.event_start_date ? {
              to: new Date(_vm.$attrs.value.event_start_date)
            } : {},"format":"dd/MM/yyyy","placeholder":"ช่วงเวลาสิ้นสุด"},model:{value:(_vm.$attrs.value.event_end_date),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "event_end_date", $$v)},expression:"$attrs.value.event_end_date"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n            "+_vm._s(errors[0])+"\n          ")]):_vm._e()]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }