var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',[_c('div',{staticClass:"vx-card__title mb-6"},[_c('h4',{class:!_vm.canDo ? 'text-center text-danger' : ''},[_vm._v(_vm._s(_vm.canDo ? 'แอดมินสร้างข้อมูลโครงการให้นักการตลาด' : ' นักการตลาดกรอกข้อมูลสำหรับ FLM เฉพาะที่มี * ดอกจันเท่านั้น'))])]),(_vm.$attrs.value.project_old_title)?_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ข้อมูลนี้ถูกสร้างมาจากโครงการต้นฉบับ ")])]),_c('FormContent',{staticClass:"flex flex-col content-center pt-2"},[_c('span',[_vm._v(_vm._s(_vm.$attrs.value.project_old_title))]),_c('span',{staticClass:"text-warning"},[_vm._v("หมายเหตุ: เนื่องจากเป็นโครงการที่ทำการคัดลอกมา จึงมีการใช้งานโฟลเดอร์รูปกับโครงกาารต้นฉบับ")])])],1):_vm._e(),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ระบุชื่อโครงการ")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"project-title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"disabled":!_vm.canDo,"name":"project-title"},model:{value:(_vm.$attrs.value.title),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "title", $$v)},expression:"$attrs.value.title"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณากรอกชื่อโครงการ")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ระบุ URL พิเศษ (Slug)")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("URL พิเศษนี้ใช้รวมกันทั้ง 3 ภาษา")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Slug","rules":"special_charecter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"disabled":!_vm.canDo || _vm.currentLang != 'th',"name":"Slug"},on:{"focus":function () { return _vm.isSlugExist = false; }},model:{value:(_vm.$attrs.value.slug),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "slug", $$v)},expression:"$attrs.value.slug"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSlugExist),expression:"isSlugExist"}],staticClass:"text-danger text-sm"},[_vm._v("slug นี้มีในระบบแล้ว กรุณากรอกข้อมูลใหม่")])]}}])}),_c('span',{staticClass:"text-gray flex flex-col"},[_vm._v("ตัวอย่าง slug: mantana-srinakarin-bangna, ladawan-rama-2")])],1)],1),(_vm.mode === 'edit')?_c('div',_vm._l((_vm.urls),function(url,index){return _c('FormLayout',{key:index},[(_vm.urls[index].indexOf('/ladawan/') === -1)?_c('FormLabel',[(index === 0)?_c('span',[_vm._v("Url โครงการ")]):_vm._e()]):_vm._e(),(_vm.urls[index].indexOf('/ladawan/') === -1)?_c('FormContent',[_c('vs-input',{staticClass:"w-full",attrs:{"disabled":true,"name":"project-url"},model:{value:(_vm.urls[index]),callback:function ($$v) {_vm.$set(_vm.urls, index, $$v)},expression:"urls[index]"}})],1):_vm._e()],1)}),1):_vm._e(),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ระบุกลุ่ม")])]),_c('FormContent',{attrs:{"customClass":"vx-col w-full md:w-5/12 xl:w-3/12"}},[_c('ValidationProvider',{attrs:{"name":"project-user","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"selectExample",attrs:{"disabled":!_vm.canDo,"name":"project-user","icon":"arrow_downward","autocomplete":""},model:{value:(_vm.$attrs.value.group_id),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "group_id", $$v)},expression:"$attrs.value.group_id"}},_vm._l((_vm.groups),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.group_name}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุกลุ่ม")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ระบุ brand")])]),_c('FormContent',{attrs:{"customClass":"vx-col w-full md:w-5/12 xl:w-3/12"}},[_c('ValidationProvider',{attrs:{"name":"project-brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"selectExample",attrs:{"disabled":!_vm.canDo,"name":"project-brand","icon":"arrow_downward","autocomplete":""},model:{value:(_vm.$attrs.value.brand_id),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "brand_id", $$v)},expression:"$attrs.value.brand_id"}},_vm._l((_vm.brands),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.brand_name}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุ brand")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ต้องการแสดงข้อมูล Brand talk")])]),_c('FormContent',[_c('vs-checkbox',{staticClass:"pt-2",attrs:{"name":"project-show-brand-talk"},model:{value:(_vm.$attrs.value.show_brand_talk),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "show_brand_talk", $$v)},expression:"$attrs.value.show_brand_talk"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }