<template>
  <vx-card title="กำหนด Banner กิจกรรม">
    <div class="vx-col mb-6">
      <div class="w-full">
        <span>** หากไม่ต้องการ Banner กิจกรรมพิเศษ ให้ ติกปุ่ม สร้างกิจกรรม ออก</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-checkbox v-model="$attrs.value.is_event">
          ถ้าต้องการสร้างชิ้นงาน Banner (กรุณาคลิกที่ปุ่ม)
        </vs-checkbox>
      </div>
    </div>
    <div 
      v-show="$attrs.value.is_event" 
      class="px-4 mb-6">
      <div class="vx-col w-full">
        <banner-form-event
          v-model="$attrs.value"
          :folder="`${baseFolder}/Banner กิจกรรม`"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import BannerFormEvent from '@/components/BannerFormEvent'
import get from 'lodash/get'
import env from '@/env'

export default {
  components: {
    BannerFormEvent,
  },
  computed: {
    baseFolder() {
      const id = get(this.$attrs.value, 'id', null)
      return `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project/${id}`
    },
  },
}
</script>
