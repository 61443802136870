var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{attrs:{"title":"ข้อมูลภาพ/แผนที่/ไฟล์ดาวน์โหลด (Admin ดำเนินการ)"}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แผนที่โครงการ (.jpg,.png,.gif ขนาดรูปแนะนำ 1920 x 1080 px)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"แผนที่โครงการ","rules":"lh_required_cld|lh_mimes:jpg,png,gif"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/แผนที่"),"width":240,"height":135,"has-delete-button":_vm.canDelete(_vm.$attrs.value.map_img),"selection-name":"map_img"},on:{"change":validate},model:{value:(_vm.$attrs.value.map_img),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "map_img", $$v)},expression:"$attrs.value.map_img"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แผนที่โครงการ (.PDF)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"แผนที่โครงการ (.PDF)","rules":"lh_required_cld|lh_mimes:pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('FileUpload',{attrs:{"folder":(_vm.baseFolder + "/แผนที่"),"has-delete-button":_vm.canDelete(_vm.$attrs.value.map_file),"selection-name":"map_file"},on:{"change":validate},model:{value:(_vm.$attrs.value.map_file),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "map_file", $$v)},expression:"$attrs.value.map_file"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("LOGO โครงการ * (.PNG ขนาดรูปไม่เกิน 150 x 150 px)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"LOGO โครงการ","rules":"required|lh_mimes:png|lh_image_max_resolution:150,150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{staticClass:"w-8/12",attrs:{"folder":(_vm.baseFolder + "/Logo"),"has-delete-button":_vm.canDelete(_vm.$attrs.value.logo),"width":0,"name":"project-logo","selection-name":"logo","has-alt-text":""},model:{value:(_vm.$attrs.value.logo),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "logo", $$v)},expression:"$attrs.value.logo"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Download Brochure (.pdf)")])]),_c('FormContent',[_c('FileUpload',{attrs:{"folder":(_vm.baseFolder + "/Brochure"),"has-delete-button":_vm.canDelete(_vm.$attrs.value.brochure),"selection-name":"brochure"},model:{value:(_vm.$attrs.value.brochure),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "brochure", $$v)},expression:"$attrs.value.brochure"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }