<template>
  <div>
    <h5 class="mb-6">{{ title }}</h5>
    <FormLayout class="flex">
      <FormLabel>
        <span>พื้นที่ใช้สอยตั้งแต่</span>
      </FormLabel>
      <FormContent class="flex space-x-2 items-center">
        <div class="vx-col w-3/12">
          <lh-input-number
            v-model="$attrs.value.living_space_from" 
            class="w-full" />
        </div>
        <div>
          <span>ถึง</span>
        </div>
        <div class="vx-col w-3/12">
          <lh-input-number
            v-model="$attrs.value.living_space_to" 
            class="w-full" />
        </div>
        <div>
          <span>ตารางเมตร</span>
        </div>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>มีแบบห้องให้เลือก</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <div class="w-3/12">
          <lh-input-number
            v-model="$attrs.value.unit_style" 
            class="w-full" />
        </div>
        <div>
          <span>แบบ</span>
        </div>
      </FormContent>
    </FormLayout>
    <div class="vx-row">
      <div class="vx-col w-full md:ml-10">
        <span>ข้อมูลยูนิต</span>
      </div>
    </div>
    <FormLayout 
      v-for="(n, i) in unitNumber" 
      :key="`unit_${i}`">
      <div class="vx-col w-full">
        <UnitSection 
          :id="n" 
          v-model="$attrs.value.project_property_units[i]" />
        <FormLayout>
          <FormLabel/>
          <FormContent class="flex space-x-4">
            <div v-if="unitNumber === n">
              <vs-button
                :disabled="!canAddUnit"
                class="mr-3 mb-2"
                @click="addUnit"
              >
                Add
              </vs-button>
            </div>
            <div v-if="unitNumber !== 1">
              <vs-button 
                class="mr-3 mb-2 vs-button-danger" 
                @click="deleteUnit(i)">Delete</vs-button>
            </div>
          </FormContent>
        </FormLayout>
      </div>
      <vs-divider v-if="unitNumber !== n" />
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>จำนวนอาคาร</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-2">
        <div class="w-3/12">
          <lh-input-number
            v-model="$attrs.value.all_building" 
            class="w-full" />
        </div>
        <div>
          <span>อาคาร</span>
        </div>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>จำนวนชั้น</span>
      </FormLabel>
      <FormContent class="flex items-center space-x-4">
        <div class="w-3/12">
          <lh-input-number
            v-model="$attrs.value.all_level" 
            class="w-full" />
        </div>
        <div>
          <span>ชั้น</span>
        </div>
      </FormContent>
    </FormLayout>
    <div class="vx-row md:ml-10">
      <div class="vx-col w-full">
        <span>ข้อมูลอาคาร</span>
      </div>
    </div>
    <FormLayout 
      v-for="(n, i) in buildingNumber" 
      :key="`building_${i}`">
      <div class="vx-col w-full">
        <BuildingSection 
          :id="n" 
          v-model="$attrs.value.project_property_buildings[i]" />
        <FormLayout>
          <FormLabel/>
          <FormContent class="flex space-x-4">
            <div v-if="buildingNumber === n">
              <vs-button
                :disabled="!canAddBuilding"
                class="mr-3 mb-2"
                @click="addBuilding"
              >Add</vs-button
              >
            </div>
            <div v-if="buildingNumber !== 1">
              <vs-button 
                class="mr-3 mb-2 vs-button-danger" 
                @click="deleteBuilding(i)"
              >Delete</vs-button
              >
            </div>
          </FormContent>
        </FormLayout>
      </div>
      <vs-divider v-if="buildingNumber !== n" />
    </FormLayout>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
// import get from 'lodash/get'
import UnitSection from './UnitSection.vue'
import BuildingSection from './BuildingSection.vue'

export default {
  components: {
    UnitSection,
    BuildingSection,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    categoryId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    unitNumber() {
      const number = isEmpty(this.$attrs.value.project_property_units)
        ? 1
        : this.$attrs.value.project_property_units.length
      return number
    },
    buildingNumber() {
      const number = isEmpty(this.$attrs.value.project_property_buildings)
        ? 1
        : this.$attrs.value.project_property_buildings.length
      return number
    },
    canAddUnit() {
      // if (isEmpty(this.$attrs.project_property_units)) return false
      const lastUnit = this.$attrs.value.project_property_units[this.unitNumber - 1]
      return lastUnit.unit_name || lastUnit.unit_size
    },
    canAddBuilding() {
      // if (isEmpty(this.$attrs.project_property_buildings)) return false
      const lastBuilding = this.$attrs.value.project_property_buildings[this.buildingNumber - 1]
      return lastBuilding.building_name || lastBuilding.building_unit || lastBuilding.building_level
    }
  },
  methods: {
    addUnit() {
      this.$attrs.value.project_property_units.push({})
    },
    deleteUnit(index) {
      this.$attrs.value.project_property_units.splice(index, 1)
    },
    addBuilding() {
      this.$attrs.value.project_property_buildings.push({})
    },
    deleteBuilding(index) {
      this.$attrs.value.project_property_buildings.splice(index, 1)
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
.con-select {
  width: 100% !important;
}
</style>
