<template>
  <vx-card title="ข้อมูลทำเลที่ตั้งและสถานที่ใกล้เคียงโครงการ">
    <FormLayout class="flex flex-col md:flex-row w-full">
      <FormLabel>
        <span>latitude</span>
      </FormLabel>
      <div class="vx-col flex items-center w-full md:w-2/12 xl:w-2/12">
        <div class="w-full">
          <lh-input-number
            v-model="$attrs.value.latitude"
            class="w-full"
            thousands-separator=""
            :scale="20"
          />
        </div>
      </div>
      <div class="vx-col flex w-auto mt-2">
        <span>longitude</span>
      </div>
      <div class="vx-col flex items-center w-full md:w-2/12 xl:w-2/12 space-x-4 mb-6 md:mb-0">
        <lh-input-number
          v-model="$attrs.value.longitude"
          class="w-full"
          thousands-separator=""
          :scale="20"
        />
      </div>
      <div class="vx-col flex items-center w-auto space-x-4 mb-6 md:mb-0 underline">
        <a
          :href="`${mediaBaseUrl}/assets/Howto_LatLong.pdf`"
          target="_blank"
        >
          วิธีการหาค่า Latitude,Longtitude คลิก
        </a>
      </div>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ระบุ URL (จาก Google Local Business)</span>
      </FormLabel>
      <FormContent>
        <vs-input 
          v-model="$attrs.value.map" 
          class="w-full" />
      </FormContent>
    </FormLayout>
    <vs-divider />
    <div 
      v-for="(n, i) in facilityNumber" 
      :key="i">
      <FacilitySection 
        :id="n" 
        :units="units" 
        v-model="$attrs.value.facilityList[i]" />
      <FormLayout>
        <FormLabel/>
        <FormContent class="flex space-x-4">
          <div v-if="facilityNumber === n">
            <vs-button 
              :disabled="!canAddFacility" 
              @click="addFacility"
            >Add</vs-button
            >
          </div>
          <div v-if="facilityNumber !== 1">
            <vs-button 
              class="vs-button-danger" 
              @click="deleteFacility(i)">Delete</vs-button>
          </div>
        </FormContent>
      </FormLayout>
      <vs-divider v-if="facilityNumber !== n" />
    </div>
  </vx-card>
</template>

<script>
import get from 'lodash/get'
import FacilitySection from './FacilitySection.vue'
import env from '@/env'

export default {
  components: {
    FacilitySection,
  },
  computed: {
    units() {
      return get(this.$store.state, ['facility', 'units'], [])
    },
    facilityNumber() {
      const number =
        this.$attrs.value.facilityList.length === 0
          ? 1
          : this.$attrs.value.facilityList.length
      return number
    },
    canAddFacility() {
      // if (isEmpty(this.$attrs.project_property_units)) return false
      const lastFacility = this.$attrs.value.facilityList[this.facilityNumber - 1]
      return lastFacility.facility_name || lastFacility.facility_distance || lastFacility.facility_distance_unit 
    },
    mediaBaseUrl () {
      return `${env.VUE_APP_IMAGE_URL}/${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}`
    },
  },
  methods: {
    addFacility() {
      this.$attrs.value.facilityList.push({
        facility_distance_unit: null
      })
    },
    deleteFacility(index) {
      this.$attrs.value.facilityList.splice(index, 1)
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 30px;
}
</style>
