<template>
  <div>
    <FormLayout>
      <FormLabel>
        {{ label }}
      </FormLabel>
      <FormContent>
        <ul class="flex space-x-4 md:mt-3">
          <li>
            <vs-radio 
              v-model="$attrs.value.event.event_type" 
              vs-value="gallery"
            >รูปภาพ</vs-radio
            >
          </li>
          <li>
            <vs-radio 
              v-model="$attrs.value.event.event_type" 
              vs-value="text_image"
            >รูปภาพและข้อความ</vs-radio
            >
          </li>
          <li>
            <vs-radio 
              v-model="$attrs.value.event.event_type" 
              vs-value="video"
            >คลิปวีดีโอ</vs-radio
            >
          </li>
        </ul>
      </FormContent>
    </FormLayout>
    <vs-divider />
    <BannerEventPictureForm
      v-show="$attrs.value.event.event_type === 'gallery'"
      :folder="folder"
      v-model="$attrs.value"
    />
    <BannerEventPictureTextForm
      v-show="$attrs.value.event.event_type === 'text_image'"
      :folder="folder"
      v-model="$attrs.value"
    />
    <BannerEventVideoForm
      v-show="$attrs.value.event.event_type === 'video'"
      :folder="folder"
      v-model="$attrs.value"
    />
  </div>
</template>

<script>
import BannerEventPictureForm from '@/views/apps/banner/event/BannerEventPictureForm'
import BannerEventVideoForm from '@/views/apps/banner/event/BannerEventVideoForm'
import BannerEventPictureTextForm from '@/views/apps/banner/event/BannerEventPictureTextForm'

import env from '@/env'

export default {
  name: 'BannerFormEvent',
  components: {
    BannerEventPictureTextForm,
    BannerEventVideoForm,
    BannerEventPictureForm,
  },
  props: {
    label: {
      type: String,
      default: 'เลือกประเภท Banner ที่ต้องการแสดงสำหรับหน้าจอที่เลือก',
    },
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/banner`,
    },
  },
}
</script>

<style scoped></style>
