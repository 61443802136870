<template>
  <div>
    <h5 class="mb-6">สิ่งอำนวยความสะดวกของโครงการ {{ id }}</h5>
    <FormLayout>
      <FormLabel>
        <span>ชื่อสิ่งอำนวยความสะดวกภายในโครงการ</span>
      </FormLabel>
      <FormContent>
        <vs-input 
          v-model="$attrs.value.property_facility_name" 
          class="w-full" />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span
        >สิ่งอำนวยความสะดวกของโครงการ ขนาด 1920 x 1080 px (.JPG , .PNG , .GIF )</span
        >
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="ภาพสิ่งอำนวยความสะดวก"
          rules="lh_mimes:jpg,png,gif"
        >
        <image-upload
          v-model="$attrs.value.property_facility_image"
          :folder="folder"
          :selection-name="`project-property-facility-image-section-${id}`"
          :width="240"
          :has-delete-button="canDelete($attrs.value.property_facility_image)"
          has-alt-text
        />
        <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >{{ errors[0] }}</span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>รายละเอียด</span>
      </FormLabel>
      <FormContent>
        <vs-textarea 
          v-model="$attrs.value.property_facility_detail" 
          class="w-full" 
          cols="10" 
          rows="4"
        />
      </FormContent>
    </FormLayout>
  </div>
</template>

<script>
import ImageUpload from '@/components/upload/ImageUpload.vue'
import isEmpty from 'lodash/isEmpty'
import env from '@/env'

export default {
  components: {
    ImageUpload,
  },
  props: {
    id: {
      type: Number,
      default: 1,
    },
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project`,
    },
  },
  methods: {
    canDelete (files) {
      return !isEmpty(files)
    }
  }
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
</style>
