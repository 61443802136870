<template>
  <vx-card >
    <div class="vx-card__title mb-6">
      <h4 :class="!canDo ? 'text-center text-danger' : ''">{{canDo ? 'แอดมินสร้างข้อมูลโครงการให้นักการตลาด' : ' นักการตลาดกรอกข้อมูลสำหรับ FLM เฉพาะที่มี * ดอกจันเท่านั้น'}}</h4>
    </div>
    <FormLayout v-if="$attrs.value.project_old_title">
      <FormLabel>
        <span>ข้อมูลนี้ถูกสร้างมาจากโครงการต้นฉบับ </span>
      </FormLabel>
      <FormContent class="flex flex-col content-center pt-2">
        <span>{{$attrs.value.project_old_title}}</span>
        <span class="text-warning">หมายเหตุ: เนื่องจากเป็นโครงการที่ทำการคัดลอกมา จึงมีการใช้งานโฟลเดอร์รูปกับโครงกาารต้นฉบับ</span>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">ระบุชื่อโครงการ</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="project-title" 
          rules="required">
          <vs-input 
            :disabled="!canDo"
            v-model="$attrs.value.title" 
            name="project-title" 
            class="w-full" />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณากรอกชื่อโครงการ</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span >ระบุ URL พิเศษ (Slug)</span><br>
        <span class="text-warning text-sm">URL พิเศษนี้ใช้รวมกันทั้ง 3 ภาษา</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="Slug" 
          rules="special_charecter">
          <vs-input 
            :disabled="!canDo || currentLang != 'th'"
            v-model="$attrs.value.slug" 
            name="Slug" 
            @focus="() => isSlugExist = false"
            class="w-full" />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >{{errors[0]}}</span
          >
          <span 
            v-show="isSlugExist" 
            class="text-danger text-sm"
          >slug นี้มีในระบบแล้ว กรุณากรอกข้อมูลใหม่</span
          >
        </ValidationProvider>
        <span class="text-gray flex flex-col">ตัวอย่าง slug: mantana-srinakarin-bangna, ladawan-rama-2</span>
      </FormContent>
    </FormLayout>
    <div v-if="mode === 'edit'">
      <FormLayout 
        v-for="(url, index) in urls" 
        :key="index">
        <FormLabel v-if="urls[index].indexOf('/ladawan/') === -1">
          <span v-if="index === 0">Url โครงการ</span>
        </FormLabel>
        <FormContent v-if="urls[index].indexOf('/ladawan/') === -1">
          <vs-input
            v-model="urls[index]"
            :disabled="true"
            name="project-url"
            class="w-full"
          />
        </FormContent>
      </FormLayout>
    </div>
    <FormLayout>
      <FormLabel>
        <span class="required">ระบุกลุ่ม</span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-5/12 xl:w-3/12">
        <ValidationProvider
          v-slot="{ errors }" 
          name="project-user" 
          rules="required">
          <vs-select
            :disabled="!canDo"
            v-model="$attrs.value.group_id"
            name="project-user"
            class="selectExample"
            icon="arrow_downward"
            autocomplete
          >
            <vs-select-item
              v-for="(item, index) in groups"
              :value="item.id"
              :text="item.group_name"
              :key="index"
            />
          </vs-select>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุกลุ่ม</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">ระบุ brand</span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-5/12 xl:w-3/12">
        <ValidationProvider 
          v-slot="{ errors }" 
          name="project-brand" 
          rules="required">
          <vs-select
            :disabled="!canDo"
            v-model="$attrs.value.brand_id"
            name="project-brand"
            class="selectExample"
            icon="arrow_downward"
            autocomplete
          >
            <vs-select-item
              v-for="(item, index) in brands"
              :value="item.id"
              :text="item.brand_name"
              :key="index"
            />
          </vs-select>
          <span 
            v-show="errors.length" 
            class="text-danger text-sm">กรุณาระบุ brand</span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ต้องการแสดงข้อมูล Brand talk</span>
      </FormLabel>
      <FormContent>
        <vs-checkbox
          class="pt-2"
          v-model="$attrs.value.show_brand_talk"
          name="project-show-brand-talk"
        >
        </vs-checkbox>
      </FormContent>
    </FormLayout>
  </vx-card>
</template>

<script>
import uniq from 'lodash/uniq'
import orderBy from 'lodash/orderBy'
export default {
  props: {
    mode: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      isSlugExist: false
    }
  },
  computed: {
    brands() {
      return this.$store.state.brand.items || []
    },
    groups() {
      const userGroups = this.$store.state.users.groups || []
      return orderBy(userGroups, ['group_name'])
    },
    canDo() {
      return this.$acl.check('editor')
    },
    urls() {
      return uniq(this.$attrs.value.urls).filter((url) => url.indexOf('/ladawan/') === -1)
    },
    currentLang() {
      return this.$store.state.locale.currentLang
    }
  },
  watch: {
    '$store.state.project.isSlugExist' (newValue) {
      if (newValue) {
        this.isSlugExist = true
      }
    }
  }
}
</script>
