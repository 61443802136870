<template>
  <vx-card>
    <div 
      v-for="(n, i) in propertyFacilityNumber" 
      :key="i">
      <PropertyFacilitySubSection
        :id="n"
        :folder="`${baseFolder}/สิ่งอำนวยความสะดวก`"
        v-model="$attrs.value.propertyFacilityList[i]"
      />
      <FormLayout>
        <FormLabel/>
        <FormContent class="flex space-x-4">
          <div v-if="propertyFacilityNumber === n">
            <vs-button
              :disabled="!canAddFacility"
              @click="addPropertyFacility"
            >Add
            </vs-button>
          </div>
          <div v-if="propertyFacilityNumber !== 1">
            <vs-button 
              class="vs-button-danger" 
              @click="deletePropertyFacility(i)">Delete</vs-button>
          </div>
        </FormContent>
      </FormLayout>
      <vs-divider v-if="propertyFacilityNumber !== n" />
    </div>
  </vx-card>
</template>

<script>
import get from 'lodash/get'
import PropertyFacilitySubSection from './PropertyFacilitySubSection.vue'
import env from '@/env'

export default {
  components: {
    PropertyFacilitySubSection,
  },
  props: {
    lang: {
      type: String,
      default: 'th',
    },
    initialValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    propertyFacilityNumber() {
      const number =
        this.$attrs.value.propertyFacilityList.length === 0
          ? 1
          : this.$attrs.value.propertyFacilityList.length
      return number
    },
    canAddFacility() {
      // if (isEmpty(this.$attrs.project_property_units)) return false
      const lastFacility = this.$attrs.value.propertyFacilityList[this.propertyFacilityNumber - 1]
      return lastFacility.property_facility_name || lastFacility.property_facility_image || lastFacility.property_facility_detail
    },
    baseFolder() {
      const id = get(this.$attrs.value, 'id', null)
      return `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project/${id}`
    },
  },
  methods: {
    addPropertyFacility() {
      this.$attrs.value.propertyFacilityList.push({})
    },
    deletePropertyFacility(index) {
      this.$attrs.value.propertyFacilityList.splice(index, 1)
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 30px;
}
</style>
