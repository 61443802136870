<template>
  <vx-card>
    <div 
      v-for="(n, i) in zoneNumber" 
      :key="i">
      <ZoneSubSection
        :id="n"
        :zones="zones"
        :raw-provinces="provinces"
        :raw-districts="districts"
        :raw-sub-districts="subDistricts"
        v-model="$attrs.value[i]"
      />
      <FormLayout>
        <FormLabel/>
        <FormContent class="flex space-x-4">
          <div v-if="zoneNumber === n">
            <vs-button 
              :disabled="!canAddZone" 
              @click="addZone">Add</vs-button>
          </div>
          <div v-if="zoneNumber !== 1">
            <vs-button 
              class="vs-button-danger" 
              @click="deleteZone(i)">Delete</vs-button>
          </div>
        </FormContent>
      </FormLayout>
      <vs-divider v-if="zoneNumber !== n" />
    </div>
  </vx-card>
</template>

<script>
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import ZoneSubSection from './ZoneSubSection.vue'

export default {
  components: {
    ZoneSubSection,
  },
  computed: {
    zoneNumber() {
      const number = this.$attrs.value.length === 0 ? 1 : this.$attrs.value.length
      return number
    },
    zones() {
      return get(this.$store.state, ['zone', 'zones'], []).filter((zone) => zone.type === 'website')
    },
    provinces() {
      return get(this.$store.state, ['geo', 'geo', 'provinces'], [])
    },
    districts() {
      return get(this.$store.state, ['geo', 'geo', 'districts'], [])
    },
    subDistricts() {
      return get(this.$store.state, ['geo', 'geo', 'subDistricts'], [])
    },
    canAddZone () {
      if (isEmpty(this.$attrs.value)) {
        return true
      }
      const lastZone = this.$attrs.value[this.zoneNumber - 1]
      return lastZone.zone_id
    }
  },
  methods: {
    addZone() {
      this.$attrs.value.push({
        zone_id: null,
        province_id: null,
        district_id: null,
        sub_district_id: null,
      })
    },
    deleteZone(index) {
      this.$attrs.value.splice(index, 1)
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 30px;
}
</style>
