<template>
  <div>
    <h5 class="mb-6">ระบุข้อมูลความคืบหน้าโครงการ Progress Update (สำหรับคอนโด)</h5>
    <FormLayout>
      <FormLabel>
        <span>อัพเดต % ความคืบหน้า [0 ถึง 100]</span>
      </FormLabel>
      <FormContent>
        <lh-input-number
          v-model="$attrs.value.progress_percent" 
          class="w-full"
          :min="0"
          :max="100"
        />
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ระบุเดือน / ปี</span>
      </FormLabel>
      <FormContent>
        <div class="mb-2 flex items-center space-x-4">
          <datepicker 
            v-model="$attrs.value.progress_date"
            clear-button
            :format="customFormatter"
            class="w-5/12 md:w-4/12 xl:w-3/12"
          />
          <span class="w-auto"
          >ของภาพความคืบหน้าโครงการที่จะใส่</span>
        </div>
      </FormContent>
    </FormLayout>
    <div class="vx-row mb-6 md:ml-10">
      <div class="vx-col w-2/12">
        <span>ระบุภาพ</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <GalleryForm
          v-model="$attrs.value.projectPropertyProgressFiles"
          :col="3"
          :folder="folder"
          :selection-name="`project-project-property-progress-file-section-${id}`"
          has-description
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import GalleryForm from '@/components/GalleryForm.vue'
import moment from 'moment'
import env from '@/env'

export default {
  components: {
    GalleryForm,
    Datepicker,
  },
  props: {
    id: {
      type: Number,
      default: 1,
    },
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project`,
    },
  },
  methods: {
    customFormatter(date) {
      return moment(date).format('DD/MM/YYYY')
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
</style>
