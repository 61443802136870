<template>
  <div>
    <FormLayout>
      <FormLabel>
        <div class="flex md:justify-end space-x-2">
          <span>ยูนิตที่ {{ id }}</span>
          <span>ชื่อ Unit</span>
        </div>
      </FormLabel>
      <FormContent class="flex items-center space-x-3">
        <div>
          <vs-input 
            v-model="$attrs.value.unit_name" 
            class="w-full" />
        </div>
        <div>
          <span>ขนาด</span>
        </div>
        <div class="w-3/12">
          <vs-input
            v-model="$attrs.value.unit_size" 
            class="w-full" />
        </div>
        <div class="w-2/12">
          <span>ตารางเมตร</span>
        </div>
      </FormContent>
    </FormLayout>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 1,
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
.con-select {
  width: 100% !important;
}
</style>
