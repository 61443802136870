var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormLayout',[_c('FormLabel',[_vm._v("\n      พื้นหลัง\n      "),_c('br'),_vm._v("(กำหนดสีพื้นหลัง Banner) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"vx-col w-full md:w-8/12 md:flex md:relative"},[_c('div',{staticClass:"w-1/2"},[_c('ValidationProvider',{attrs:{"rules":_vm.$attrs.value.is_event && _vm.$attrs.value.event.event_type === 'text_image'
              ? 'required'
              : '',"name":"สีพื้นหลัง"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.color),expression:"state.color"}],ref:"colorPicker",staticClass:"color-input vs-inputx vs-input--input normal",style:({
              backgroundColor: _vm.backgroundColor,
              color: _vm.textColor
            }),attrs:{"autocomplete":"off","name":"event_background_color"},domProps:{"value":(_vm.state.color)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.state, "color", $event.target.value)}}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n            "+_vm._s(errors[0])+"\n          ")]):_vm._e()]}}])})],1)])],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n      รูปภาพ Activity สูงสุด 1 รูป "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"rules":_vm.$attrs.value.is_event && _vm.$attrs.value.event.event_type === 'text_image'
            ? 'required|lh_mimes:jpg,png,gif|lh_ratio:16,9'
            : 'lh_ratio:16,9',"name":"รูปภาพ Activity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":_vm.folder,"has-delete-button":_vm.canDelete(_vm.$attrs.value.event.event_activity_image),"selection-name":"thumbnail"},model:{value:(_vm.$attrs.value.event.event_activity_image),callback:function ($$v) {_vm.$set(_vm.$attrs.value.event, "event_activity_image", $$v)},expression:"$attrs.value.event.event_activity_image"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")]):_vm._e()]}}])})],1)],1),_c('FormLayout',{staticClass:"flex flex-col"},[_c('FormLabel',{attrs:{"custom-class":"vx-col w-full md:w-6/12 pt-2"}},[_vm._v("\n      เนื้อหา Activity\n      "),_c('p',{staticClass:"text-warning"},[_vm._v("**Support การ Upload Video ขนาดไม่เกิน 98MB")])]),_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"content-editor"},[_c('Wyswyg',{attrs:{"folder":(_vm.folder + "/wyswyg")},model:{value:(_vm.$attrs.value.event.event_activity_content),callback:function ($$v) {_vm.$set(_vm.$attrs.value.event, "event_activity_content", $$v)},expression:"$attrs.value.event.event_activity_content"}})],1)])],1),_c('BannerDatepickerForm',{staticClass:"mt-6",attrs:{"event-type":"text_image"},model:{value:(_vm.$attrs.value),callback:function ($$v) {_vm.$set(_vm.$attrs, "value", $$v)},expression:"$attrs.value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }