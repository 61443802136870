<template>
  <vx-card
    title="ภาพ Gallery"
    subtitle="ตำแหน่งภาพ Gallery (.JPG , .PNG , .GIF )"
  >
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <ValidationProvider
          v-slot="{ errors, validate }"
          name="Gallery"
          rules="lh_mimes:jpg,png,gif"
        >
          <GalleryForm
            v-model="$attrs.value.gallery"
            :folder="`${baseFolder}/Gallery`"
            has-detail
            has-alt-text
            has-index
            selection-name="project-gallery-section"
            @change="validate"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </vx-card>
</template>

<script>
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import GalleryForm from '@/components/GalleryForm.vue'
import env from '@/env'

export default {
  components: {
    GalleryForm,
  },
  computed: {
    baseFolder() {
      const id = get(this.$attrs.value, 'id', null)
      return `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/project/${id}`
    },
  },
  watch: {
    "$attrs.value.gallery": {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler (newValue) {
        const uniqValue = uniqBy(newValue, (value) => value.public_id)
        if (uniqValue.length !== newValue.length) this.$emit('input', { gallery: uniqValue })
      }
    }
  }
}
</script>
