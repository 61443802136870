var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"mb-6"},[_vm._v("ทำเลที่ "+_vm._s(_vm.id))]),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ระบุ zone")])]),_c('FormContent',{attrs:{"customClass":"vx-col w-full md:w-5/12 xl:w-4/12"}},[_c('ValidationProvider',{attrs:{"name":("project-zone-" + _vm.id),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"selectExample",attrs:{"disabled":!_vm.canDo,"name":("project-zone-" + _vm.id),"icon":"arrow_downward","autocomplete":""},on:{"change":_vm.onChangeZone},model:{value:(_vm.$attrs.value.zone_id),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "zone_id", $$v)},expression:"$attrs.value.zone_id"}},_vm._l((_vm.zones),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.zone_title}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุ zone")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ระบุชื่อจังหวัด")])]),_c('FormContent',{attrs:{"customClass":"vx-col w-full md:w-5/12 xl:w-4/12"}},[_c('ValidationProvider',{attrs:{"name":("project-zone-province-" + _vm.id),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"selectExample",attrs:{"name":("project-zone-province-" + _vm.id),"value":_vm.$attrs.value.province_id,"disabled":true,"icon":"arrow_downward","autocomplete":""}},_vm._l((_vm.provinces),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.title}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุจังหวัด")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ระบุชื่ออำเภอ")])]),_c('FormContent',{attrs:{"customClass":"vx-col w-full md:w-5/12 xl:w-4/12"}},[_c('ValidationProvider',{attrs:{"name":("project-zone-district-" + _vm.id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"selectExample",attrs:{"name":("project-zone-district-" + _vm.id),"disabled":_vm.isNil(_vm.$attrs.value.province_id),"icon":"arrow_downward","autocomplete":""},model:{value:(_vm.$attrs.value.district_id),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "district_id", $$v)},expression:"$attrs.value.district_id"}},_vm._l((_vm.districts),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.title}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุอำเภอ")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ระบุชื่อตำบล")])]),_c('FormContent',{attrs:{"customClass":"vx-col w-full md:w-5/12 xl:w-4/12"}},[_c('ValidationProvider',{attrs:{"name":("project-zone-sub-district-" + _vm.id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"selectExample",attrs:{"name":("project-zone-sub-district-" + _vm.id),"disabled":_vm.isNil(_vm.$attrs.value.district_id),"icon":"arrow_downward","autocomplete":""},model:{value:(_vm.$attrs.value.sub_district_id),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "sub_district_id", $$v)},expression:"$attrs.value.sub_district_id"}},_vm._l((_vm.subDistricts),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.title}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุตำบล")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }