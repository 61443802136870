<template>
  <div>
    <div class="vx-row" v-if="isHouse">
      <div class="vx-col w-full mb-base">
        <vx-card class="flex justify-end">
          <vs-button 
            v-if="currentLang == 'th'"
            :to="{ name: 'property-asset-project-home-sell', params: {
              project_id: this.$route.params.id
          } }">
            ข้อมูลบ้านพร้อมขาย
          </vs-button>
        </vx-card>
      </div>
    </div>
    <ValidationObserver v-slot="{ errors, validate }">
      <form @submit.prevent="submit(validate)">
        <div class="vx-row">
          <!-- HORIZONTAL LAYOUT -->
          <div class="vx-col w-full mb-base">
            <div class="vx-row">
              <div class="vx-col w-full mb-base">
                <BasicInfoSection 
                  :mode="mode" 
                  v-model="formData.basicInfo"/>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mb-base">
                <ZoneSection v-model="formData.zoneList"/>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mb-base">
                <PropertySection
                  v-model="formData.property"
                  :mode="mode"
                  :categories="categories"
                  :lang="lang"
                />
              </div>
            </div>
            <div 
              v-if="mode === 'edit'" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <InfoSection
                  v-model="formData.info"
                  :isCondo="isCondominium"
                />
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <MapSection v-model="formData.map"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <LeadImageSection v-model="formData.leadImage"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <gallery-section v-model="formData.gallery"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <promotion-gallery-section v-model="formData.promotion_gallery"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <vdo-section v-model="formData.videoInfo"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && isCondominium && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <master-plan-section v-model="formData.masterPlan"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && isCondominium && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <unit-plan-section v-model="formData.unitPlan"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && isCondominium && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <property-facility-section
                  :initial-value="initialProductDetail"
                  :lang="lang"
                  v-model="formData.propertyFacility"
                />
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && isCondominium && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <progress-section v-model="formData.progressInfo"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit'" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <LocationSection v-model="formData.location"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit'" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <ContactSection
                  v-model="formData.contact"
                  :projectZonesLength="formData.zoneList.length"
                />
              </div>
            </div>
            <div
              v-if="canDo" 
              class="vx-row"
            >
              <div class="vx-col w-full mb-base">
                <seo-section v-model="formData.seo"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <banner-section v-model="formData.banner"/>
              </div>
            </div>
            <div 
              v-if="mode === 'edit' && canDo" 
              class="vx-row">
              <div class="vx-col w-full mb-base">
                <admin-section v-model="formData.admin"/>
              </div>
            </div>
            <vx-card>
              <FormLayout>
                <FormLabel>
                  <span>แก้ไขล่าสุดโดย</span>
                </FormLabel>
                <div class="flex items-center vx-col w-full md:w-6/12">
                  <span class="mt-2"> {{formData.updated_by}}</span>
                </div>
              </FormLayout>
              <FormLayout>
                <FormLabel>
                  <span>แก้ไขล่าสุดวันที่</span>
                </FormLabel>
                <div class="flex items-center vx-col w-full md:w-6/12">
                  <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
                </div>
              </FormLayout>
              <FormLayout>
                <FormLabel/>
                <FormContent class="flex space-x-4">
                  <vs-button
                    v-if="mode === 'edit'"
                    type="border"
                    color="warning"
                    @click.prevent="previewProject"
                  >Preview
                  </vs-button
                  >
                  <vs-button 
                    :loading="loading" 
                    @click.prevent="submit(validate)">{{
                      mode === 'edit' ? 'Update' : 'Submit'
                    }}
                  </vs-button>
                  <vs-button
                    v-if="mode === 'edit' && canDo"
                    color="danger"
                    @click.prevent="useProjectUtil.deleteItem(project.uuid)"
                  >Delete
                  </vs-button
                  >
                </FormContent>
              </FormLayout>
            </vx-card>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import ZoneSection from './Section/ZoneSection.vue'
import BasicInfoSection from './Section/BasicInfoSection.vue'
import InfoSection from './Section/InfoSection.vue'
import MapSection from './Section/MapSection.vue'
import LeadImageSection from './Section/LeadImageSection.vue'
import GallerySection from './Section/GallerySection.vue'
import PromotionGallerySection from './Section/PromotionGallerySection.vue'
import VdoSection from './Section/VdoSection.vue'
import MasterPlanSection from './Section/MasterPlanSection.vue'
import UnitPlanSection from './Section/UnitPlanSection.vue'
import ProgressSection from './Section/ProgressSection.vue'
import PropertySection from './Section/PropertySection.vue'
import PropertyFacilitySection from './Section/PropertyFacilitySection.vue'
import LocationSection from './Section/LocationSection.vue'
import ContactSection from './Section/ContactSection.vue'
import SeoSection from './Section/SeoSection.vue'
import AdminSection from './Section/AdminSection.vue'
import BannerSection from './Section/BannerSection.vue'

import useNotify from '@/use/useNotify'
import useProjectUtil from '@/use/useProjectUtil'

import { buildProjectBody, buildFormProjectData } from '@/helper/Utils'

export default {
  name: 'ProjectForm',
  setup(props, ctx) {
    return {
      useNotify: useNotify(ctx),
      useProjectUtil: useProjectUtil(ctx),
    }
  },
  components: {
    ZoneSection,
    BasicInfoSection,
    PropertySection,
    InfoSection,
    LocationSection,
    ContactSection,
    SeoSection,
    AdminSection,
    MapSection,
    LeadImageSection,
    GallerySection,
    PromotionGallerySection,
    MasterPlanSection,
    UnitPlanSection,
    VdoSection,
    ProgressSection,
    PropertyFacilitySection,
    BannerSection,
  },
  data() {
    return {
      formData: {
        basicInfo: {
          title: null,
          group_id: null,
          brand_id: null,
          usrls: [],
          show_brand_talk: true,
          slug: null,
        },
        zoneList: [{}],
        info: {},
        contact: {
          officeDay: [1, 2, 3, 4, 5, 6, 7], // all day mon - sun
          location_flm: []
        },
        location: {
          facilityList: [{}],
        },
        seo: {},
        admin: {},
        property: {
          propertyTypes: [],
          propertyList: [],
          pageLinks: [],
        },
        map: {},
        leadImage: {
          lead_image: [],
        },
        gallery: {
          gallery: [],
        },
        promotion_gallery: {
          promotion_gallery: [],
        },
        videoInfo: {},
        masterPlan: {
          master_plan: [],
        },
        unitPlan: {
          unitPlanList: [{}],
        },
        propertyInfo: {
          progressList: [{}],
        },
        propertyFacility: {
          propertyFacilityList: [{}],
        },
        progressInfo: {
          progressList: [{}],
        },
        banner: {
          is_event: false,
          page_url: {
            value: 'project',
          },
          event: {
            event_desktop_banner: [],
            event_mobile_banner: [],
            event_background_color: '',
            event_activity_image: [],
            event_activity_content: '',
            event_type: 'gallery',
            event_video_type: 'video_youtube',
            event_video_url: '',
            event_video_thumbnail: '',
            event_video_file: [],
            event_start_date: '',
            event_end_date: '',
          },
        },
      },
      mode: 'create',
      pageLoading: true,
      loading: false,
      lang: 'th',
      initialProductDetail: {},
    }
  },
  computed: {
    isHouse() {
      const projectPropertyType = get(this.formData, ['property', 'propertyTypes'], [])
      let isHouse = false
      isHouse = projectPropertyType.some((catId) => {
        const category = this.getCategory(catId)
        return category.property_type === 'house'
      })

      return isHouse
    },
    isCondominium() {
      const projectPropertyType = get(this.formData, ['property', 'propertyTypes'], [])
      let isCondominium = false
      isCondominium = projectPropertyType.some((catId) => {
        const category = this.getCategory(catId)
        return category.property_type === 'condominium'
      })

      return isCondominium
    },
    categories() {
      return get(this.$store.state, ['category', 'categories'], [])
    },
    pages() {
      return get(this.$store.state, ['page', 'pages'], [])
    },
    projectCategoryUrls() {
      return get(this.$store.state, ['category', 'categories'], [])
    },
    project() {
      return get(this.$store.state, ['project', 'project'], null)
    },
    brands() {
      return get(this.$store.state, ["brand", "items"], [])
    },
    canDo() {
      return this.$acl.check('editor')
    },
    currentLang() {
      return this.$store.state.locale.currentLang
    }
  },
  async created() {
    if (this.$route.path !== '/property-asset/project/create') {
      this.mode = 'edit'
    }

    const projectId = get(this.$router.currentRoute.params, 'id', null)
    const queryLang = get(this.$router.currentRoute.query, 'lang', null)
    
    if (queryLang) this.$store.dispatch('locale/changeLang', queryLang)

    await this.$store.dispatch('brand/fetch')
    this.$store.dispatch('page/fetchPages')
    this.$store.dispatch('facility/fetchUnitItems')
    this.$store.dispatch('zone/fetchZoneListItems')
    this.$store.dispatch('geo/fetchProvinceListItems')
    this.$store.dispatch('geo/fetchDistrictListItems')
    this.$store.dispatch('geo/fetchSubDistrictListItems')
    this.$store.dispatch('users/fetchUserGroup')
    this.$store.dispatch('category/fetchDataListItems', 'th')

    if (projectId) {
      await this.$vs.loading()
      await this.$store.dispatch('project/fetchItem', projectId).then(async () => {
        const productDetail = get(this.$store.state, ['project', 'project'], null)

        // const oldLangObj = translates.find((translate) => translate.attribute_code === 'project_original_language')
        const oldLang = get(productDetail, [productDetail.lang, 'project_original_language'], null)
        const oldProject = oldLang ? await this.$store.dispatch('project/fetchProjectByIdLang', { projectId, lang: oldLang }) : null

        if (!this.$acl.check('editor')) {
          const user = this.$store.state.AppActiveUser
          const groupName = get(user, 'groupName', null)
          const groups = this.$store.state.users.groups
          const group = !isEmpty(groups) ? groups.find((g) => g.id === productDetail.group_id) : {}

          if (!groupName.includes(group.group_name)) {
            this.$vs.loading.close()
            return this.$router.push({ name: 'page-not-authorized' })
          }
        }

        if (isEmpty(productDetail)) {
          this.pageLoading = false
          this.$vs.loading.close()
          return this.$router.push({ name: 'page-error-404' })
        }
        this.formData = buildFormProjectData(
          this.formData,
          productDetail,
          this.$store.state.locale.currentLang,
          oldProject
        )
        this.formData.id = this.$store.state.project.project.id
        this.formData.uuid = this.$store.state.project.project.uuid

        // check brand id
        const brand_id = this.formData.basicInfo.brand_id
        if (brand_id && !isEmpty(this.brands)) {
          const brand = this.brands.find((b) => b.id === brand_id)
          if (!brand) this.formData.basicInfo.brand_id = null
        }

        this.formData.videoInfo.video_type =
          this.formData.videoInfo.video_type || 'youtube'
        this.formData.admin.project_status =
          this.formData.admin.project_status || 'not_specified'
        this.formData.banner.event.event_video_type =
          this.formData.banner.event.event_video_type || 'video_youtube'
        this.$vs.loading.close()
      })
    } else {
      this.projectDetail = {}
      this.pageLoading = false
    }
  },
  methods: {
    formatDate(date) {
      moment.locale('th')
      return date ? moment(date).format('LLL') : ''
    },
    getCategory(id) {
      if (isEmpty(this.categories)) return {}

      return this.categories.find((cat) => cat.id === id)
    },
    submit(validate) {
      const callback = () => {
        this.$vs.loading()
        validate().then((result) => {
          if (result) {
            return this.saveProject()
          }

          this.$vs.loading.close()

          // scroll to top when not input slug
          // if (!this.formData.basicInfo.slug) {
          //   this.$vs.loading.close()
          //   document.body.scrollTop = 0
          //   document.documentElement.scrollTop = 0
          //   return this.useNotify.error({
          //     text: "กรุณาระบุ Slug"
          //   })
          // }

          this.useNotify.error({
            text: 'กรุณาตรวจสอบข้อมูลที่กรอก',
          })
        })
      }

      if (this.mode === 'edit') {
        return this.useNotify.confirm({
          callback,
        })
      }

      callback()
    },
    previewProject() {
      const path = get(this.formData, 'basicInfo.urls.0')
      window.open(path)
    },
    async saveProject() {
      // omit line contact when not admin
      const productDetail = get(this.$store.state, ['project', 'project'], null)

      if (!this.canDo && this.formData.contact.line_contact) {
        const oldLine =  get(productDetail, ['th', 'line_contact'], null)
        this.formData.contact.line_contact = oldLine
      }

      if (this.formData.basicInfo.slug) {
        // replace space with '-'
        this.formData.basicInfo.slug = this.formData.basicInfo.slug ? this.formData.basicInfo.slug.replace(/\s+/g, '-') : this.formData.basicInfo.slug
        
        const isExist = await this.$store.dispatch('project/checkSlugExist', this.formData)
        if (isExist) {
          this.$vs.loading.close()
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0

          return this.useNotify.error({
            text: "มี Slug โครงการนี้ในระบบแล้ว"
          })
        }
      }

      const normalizeData = buildProjectBody(
        this.formData,
        this.categories,
        this.pages,
        this.$store.state.locale.currentLang,
      )

      // add created_by user
      const user = this.$store.state.AppActiveUser
      const email = get(user, 'email', null)

      if (email) {
        if (!normalizeData.created_by) {
          normalizeData.created_by = email
        }
        normalizeData.updated_by = email
      }

      if (this.mode === 'create') {
        this.$store
          .dispatch('project/addProject', normalizeData)
          .then(() => {
            this.$vs.loading.close()
            this.$router.push({ name: 'property-asset-project' })
            this.useNotify.success({
              text: 'Create project successfully.',
            })
          })
          .catch((error) => {
            this.$vs.loading.close()
            let text = 'Something went wrong please see console log'
            if (error.isExist) text = error.message

            this.useNotify.error({
              text
            })
          })
      } else {
        // this.$vs.loading.close()
        normalizeData.id = this.$store.state.project.project.id
        normalizeData.uuid = this.$store.state.project.project.uuid

        this.$store
          .dispatch('project/updateProject', normalizeData)
          .then(() => {
            this.$vs.loading.close()
            this.$router.push({ name: 'property-asset-project' })
            this.useNotify.success({
              text: 'Update project successfully.',
            })
          })
          .catch((error) => {
            this.$vs.loading.close()
            let text = 'Something went wrong please see console log'
            if (error.isExist) text = error.message

            this.useNotify.error({
              text
            })

          })
      }
    },
  },
}
</script>

<style>
.required:after {
  content: ' *';
  color: red;
}
</style>
