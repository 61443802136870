<template>
  <div>
    <h5 class="mb-6">ทำเลที่ {{ id }}</h5>
    <FormLayout>
      <FormLabel>
        <span class="required">ระบุ zone</span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-5/12 xl:w-4/12">
        <ValidationProvider 
          v-slot="{ errors }" 
          :name="`project-zone-${id}`" 
          rules="required">
          <vs-select
            :disabled="!canDo"
            :name="`project-zone-${id}`"
            v-model="$attrs.value.zone_id"
            class="selectExample"
            icon="arrow_downward"
            autocomplete
            @change="onChangeZone"
          >
            <vs-select-item
              v-for="(item, index) in zones"
              :value="item.id"
              :text="item.zone_title"
              :key="index"
            />
          </vs-select>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุ zone</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">ระบุชื่อจังหวัด</span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-5/12 xl:w-4/12">
        <ValidationProvider
          v-slot="{ errors }"
          :name="`project-zone-province-${id}`"
          rules="required"
        >
          <vs-select
            :name="`project-zone-province-${id}`"
            :value="$attrs.value.province_id"
            :disabled="true"
            class="selectExample"
            icon="arrow_downward"
            autocomplete
          >
            <vs-select-item
              v-for="(item, index) in provinces"
              :value="item.id"
              :text="item.title"
              :key="index"
            />
          </vs-select>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุจังหวัด</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ระบุชื่ออำเภอ</span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-5/12 xl:w-4/12">
        <ValidationProvider
          v-slot="{ errors }"
          :name="`project-zone-district-${id}`"
        >
          <vs-select
            :name="`project-zone-district-${id}`"
            v-model="$attrs.value.district_id"
            :disabled="isNil($attrs.value.province_id)"
            class="selectExample"
            icon="arrow_downward"
            autocomplete
          >
            <vs-select-item
              v-for="(item, index) in districts"
              :value="item.id"
              :text="item.title"
              :key="index"
            />
          </vs-select>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุอำเภอ</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ระบุชื่อตำบล</span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-5/12 xl:w-4/12">
        <ValidationProvider
          v-slot="{ errors }"
          :name="`project-zone-sub-district-${id}`"
        >
          <vs-select
            :name="`project-zone-sub-district-${id}`"
            v-model="$attrs.value.sub_district_id"
            :disabled="isNil($attrs.value.district_id)"
            class="selectExample"
            icon="arrow_downward"
            autocomplete
          >
            <vs-select-item
              v-for="(item, index) in subDistricts"
              :value="item.id"
              :text="item.title"
              :key="index"
            />
          </vs-select>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุตำบล</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
  </div>
</template>

<script>
import isNilLodash from 'lodash/isNil'
import get from 'lodash/get'

const BANGKOK_GROUP_PROVINCE_ID = [1, 2, 3, 4, 59]

export default {
  props: {
    id: {
      type: Number,
      default: 1,
    },
    zones: {
      type: Array,
      default: () => [],
      required: true,
    },
    rawProvinces: {
      type: Array,
      default: () => [],
      required: true,
    },
    rawDistricts: {
      type: Array,
      default: () => [],
      required: true,
    },
    rawSubDistricts: {
      type: Array,
      default: () => [],
      required: true,
    },
    initialForm: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    '$attrs.value.zone_id': {
      deep: true,
      handler(newId, oldId) {
        if (newId !== oldId && oldId) {
          this.$attrs.value.district_id = null
          this.$attrs.value.sub_district_id = null
        }
      },
    },
    '$attrs.value.district_id': {
      deep: true,
      handler(newId, oldId) {        
        if (newId !== oldId && oldId) {
          this.$attrs.value.sub_district_id = null
        }
      },
    },
  },
  computed: {
    canDo() {
      return this.$acl.check('editor')
    },
    zone() {
      return this.zones.find((zone) => zone.id === this.$attrs.value.zone_id)
    },
    provinces() {
      return this.rawProvinces.filter((province) => {
        return (
          province.country_id === 1 && province.id === get(this.zone, 'province_id') // default
        )
      })
    },
    districts() {
      const filterDistrict = this.rawDistricts.filter(
        (districts) => {
          // FIXME: Bangkok
          if (this.$attrs.value.province_id !== 1) {
            return districts.province_id === this.$attrs.value.province_id
          } else {
            return BANGKOK_GROUP_PROVINCE_ID.indexOf(districts.province_id) > -1
          }
        },
      )
      return [
        {
          id: null,
          title: "เลือกอำเภอ"
        },
        ...filterDistrict
      ]
    },
    subDistricts() {
      const filterSubDistrict = this.rawSubDistricts.filter(
        (province) => province.district_id === this.$attrs.value.district_id,
      )

      return [
         {
          id: null,
          title: "เลือกตำบล"
        },
        ...filterSubDistrict
      ]
    },
  },
  methods: {
    isNil(data) {
      return isNilLodash(data)
    },
    onChangeZone() {
      const zone = this.zone
      if (zone) {
        this.$attrs.value.province_id = zone.province_id
      }
    },
  },
}
</script>

<style lang="css">
.vs-select--input {
  height: 100% !important;
}
</style>
