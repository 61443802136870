<template>
  <div>
    <FormLayout>
      <div class="w-full text-center mb-3">
        ตำแหน่งรูปภาพหลักของหน้า PC * (.jpg)
        <br>
        <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
        <br>
        <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
      </div>
      <div class="w-full">
        <ValidationProvider
          v-slot="{ errors }"
          :rules="
            $attrs.value.is_event && $attrs.value.event.event_type === 'gallery'
              ? 'required|lh_ratio:16,9'
              : 'lh_ratio:16,9'
          "
          name="ตำแหน่งรูปภาพหลักของหน้า PC"
        >
          <GalleryForm
            :folder="folder"
            v-model="$attrs.value.event.event_desktop_banner"
            :col="5"
            has-alt-text
            has-ex-url
            has-date
            selection-name="event_desktop_banner"
          />
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">
            กรุณาเลือกรูป
          </span>
        </ValidationProvider>
      </div>
    </FormLayout>
    <vs-divider v-show="$attrs.value.page_url.value === 'home'" />
    <FormLayout v-show="$attrs.value.page_url.value === 'home'">
      <div class="w-full text-center mb-3 border-t border-danger">
        ตำแหน่งรูปภาพหลักของหน้า Mobile * (.jpg ขนาด 1080 x 1920 px)
      </div>
      <div class="w-full">
        <GalleryForm
          :folder="folder"
          v-model="$attrs.value.event.event_mobile_banner"
          has-alt-text
          has-ex-url
          has-date
          selection-name="event_mobile_banner"
        />
      </div>
    </FormLayout>
    <!--    <BannerDatepickerForm v-model="$attrs.value.event" />-->
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import GalleryForm from '@/components/GalleryForm'
import env from '@/env'

export default {
  name: 'BannerEventPictureForm',
  components: { GalleryForm },
  props: {
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/banner`,
    },
  },
  setup(props, { attrs }) {
    const pcBanner = ref([])
    const galleryPcRules = computed(() => {
      console.log({
        attrs,
        condition: attrs.value.is_event && attrs.value.event.event_type === 'gallery',
      })
      if (attrs.value.is_event && attrs.value.event.event_type === 'gallery') {
        return 'required'
      }

      return ''
    })

    return {
      pcBanner,
      galleryPcRules,
    }
  },
}
</script>

<style scoped></style>
