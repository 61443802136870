<template>
  <vx-card title="ข้อมูลติดต่อโครงการ">
    <FormLayout>
      <FormLabel>
        <span class="required">เปิดทำการ</span>
      </FormLabel>
      <FormContent>
        <ul class="leftx">
          <li>
            <vs-radio
              v-model="$attrs.value.office_day_type"
              vs-name="all_day"
              vs-value="all_day"
              @input="onChange"
            >
              <div class="vx-row items-center">
                <div class="vx-col w-full">
                  <span>เปิดทำการทุกวัน</span>
                </div>
              </div>
            </vs-radio>
          </li>
          <li class>
            <vs-radio
              v-model="$attrs.value.office_day_type"
              vs-name="some_day"
              vs-value="some_day"
            >
              <div class="vx-row items-center">
                <div class="vx-col w-full">
                  <span>เปิดทำการทุกวันยกเว้นวัน</span>
                </div>
              </div>
            </vs-radio>
          </li>
        </ul>
      </FormContent>
    </FormLayout>
    <FormLayout v-if="$attrs.value.office_day_type === 'some_day'">
      <FormLabel>
        <span class="required">วันที่เปิดทำการ</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="project-office-day"
          rules="required"
        >
          <div 
            v-show="errors.length > 0" 
            class="vx-row items-center">
            <div class="vx-col w-full">
              <span 
                class="text-danger text-sm"
              >กรุณาเลือกวันเปิดทำการอย่างน้อย 1 วัน</span
              >
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col">
              <vs-checkbox
                v-model="$attrs.value.office_days"
                name="project-office-day"
                class="w-full"
                vs-value="0"
              >วันหยุดนักขัตฤกษ์</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col">
              <vs-checkbox
                v-model="$attrs.value.office_days"
                name="project-office-day"
                class="w-full"
                vs-value="1"
              >วันจันทร์</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col">
              <vs-checkbox 
                v-model="$attrs.value.office_days" 
                class="w-full" 
                vs-value="2"
              >วันอังคาร</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col">
              <vs-checkbox 
                v-model="$attrs.value.office_days" 
                class="w-full" 
                vs-value="3"
              >วันพุทธ</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col">
              <vs-checkbox 
                v-model="$attrs.value.office_days" 
                class="w-full" 
                vs-value="4"
              >วันพฤหัสบดี</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col">
              <vs-checkbox 
                v-model="$attrs.value.office_days" 
                class="w-full" 
                vs-value="5"
              >วันศุกร์</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col">
              <vs-checkbox 
                v-model="$attrs.value.office_days" 
                class="w-full" 
                vs-value="6"
              >วันเสาร์</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col">
              <vs-checkbox 
                v-model="$attrs.value.office_days" 
                class="w-full" 
                vs-value="7"
              >วันอาทิตย์</vs-checkbox
              >
            </div>
          </div>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>เปิดทำการ ตั้งแต่เวลา</span>
      </FormLabel>
      <FormContent>
        <div class="vx-row mb-2 w-full md:w-9/12 xl:w-7/12 flex items-center space-x-4">
          <div class="vx-col flex-1">
            <ValidationProvider
              v-slot="{ errors }"
              name="project-open-time"
              :rules="$attrs.value.close_time ? 'required' : '' "
            >
              <lh-input-time
                v-model="$attrs.value.open_time"
                name="project-open-time"
                class="w-full"
                placeholder="00.00"
              />
              <span 
                v-show="errors.length > 0" 
                class="text-danger text-sm"
              >กรุณาระบุเวลาเปิดทำการ</span
              >
            </ValidationProvider>
          </div>
          <span>ถึงเวลา</span>
          <div class="vx-col flex-1">
            <ValidationProvider
              v-slot="{ errors }"
              name="project-close-time"
              :rules="$attrs.value.open_time ? 'required' : '' "
            >
              <lh-input-time
                v-model="$attrs.value.close_time"
                name="project-close-time"
                class="w-full"
                placeholder="00.00"
              />
              <span 
                v-show="errors.length > 0" 
                class="text-danger text-sm"
              >กรุณาระบุเวลาปิดทำการ</span
              >
            </ValidationProvider>
          </div>
        </div>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">เบอร์โทรศัพท์</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="project-telephone" 
          rules="required">
          <div class="mb-2 flex items-center space-x-4">
            <vs-input
              v-model="$attrs.value.telephone"
              class="w-4/12 md:w-5/12 xl:w-4/12"
              name="project-telephone"
            />
            <span>* ระบุ 1198</span>
          </div>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุเบอร์โทรศัพท์</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">E-mail Address</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="email" 
          rules="required|lh_email">
          <div class="mb-2 flex items-center space-x-4">
            <vs-input
              v-model="$attrs.value.email_address"
              name="project-email"
              class="w-5/12 md:w-7/12 xl:w-4/12"
            />
            <span 
            >@lh.co.th</span>
          </div>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >{{errors[0]}}</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
        <FormLayout>
      <FormLabel>
        <span>Line Link</span>
      </FormLabel>
      <FormContent>
        <div class="mb-2 flex items-center space-x-4">
          <vs-input
            v-model="$attrs.value.line_contact"
            name="project-line"
            :disabled="!isEditor && !isSuperEditor"
            class="w-5/12 md:w-7/12 xl:w-4/12"
          />
          <span class="text-red">* แจ้งใส่ LINE กับทางแอดมิน COCO </span>
        </div>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">เบอร์โทรสายตรงโครงการ</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="project-direct-phone"
          rules="required"
        >
          <vs-input
            v-model="$attrs.value.direct_telephone"
            name="project-direct-phone"
            class="w-full"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุ เบอร์โทรสายตรงโครงการ</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout
      v-for="index in projectZonesLength"
      :key="index"
    >
      <FormLabel>
        <span class="required">ทำเลที่ตั้งสำหรับ FLM ลำดับที่ {{index}}</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          :name="`ทำเลที่ตั้งสำหรับ FLM ลำดับที่ ${index}`"
          rules="required"
        >
          <vs-select
            v-model="$attrs.value.location_flm[index-1]"
            :name="`project-location-flm-${index}`"
            class="selectExample"
            icon="arrow_downward"
            autocomplete
          >
            <vs-select-item
              v-for="(item, index) in zones"
              :value="item.id"
              :text="item.zone_title"
              :key="index"
            />
          </vs-select>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุ ทำเลที่ตั้งสำหรับ FLM {{index}}</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">ชื่อ-นามสกุลเจ้าหน้าที่ฝ่ายขายประจำโครงการ</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="project-sale-name" 
          rules="required">
          <vs-input
            v-model="$attrs.value.sale_name"
            name="project-sale-name"
            class="w-full"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุ ข้อมูลเจ้าหน้าที่ฝ่ายขาย</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ระบุค่าส่วนกลาง</span>
      </FormLabel>
      <FormContent class="flex space-x-4">
        <lh-input-number
          v-model="$attrs.value.common_fee" 
          class="w-full" />
        <vs-select
          v-model="$attrs.value.common_fee_unit"
          class="selectExample"
          icon="arrow_downward"
          autocomplete
        >
          <vs-select-item
            v-for="(item, index) in unitPriceList"
            :value="item.title"
            :text="item.title"
            :key="index"
          />
        </vs-select>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ชำระล่วงหน้าถึงวันที่</span>
      </FormLabel>
      <FormContent>
        <datepicker
          v-model="$attrs.value.common_fee_date"
          :format="customFormatter"
          class="w-full"
        />
      </FormContent>
    </FormLayout>
  </vx-card>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import get from 'lodash/get'

export default {
  name: 'ContactSection',
  props: {
    projectZonesLength: {
      type: Number,
      default: 0
    },
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      officeDayType: 'all_day',
    }
  },
  computed: {
    unitPriceList() {
      return [
        {
          value: 1,
          title: 'หน่วย',
        },
        {
          value: 2,
          title: 'บาท/ตารางเมตร/เดือน',
        },
        {
          value: 3,
          title: 'บาท/ตารางวา/เดือน',
        },
        {
          value: 4,
          title: 'บาท/เดือน',
        },
      ]
    },
    zones() {
      const flmZone = get(this.$store.state, ['zone', 'zones'], []).filter((zone) => zone.type === 'flm')
      flmZone.unshift({ id: null, zone_title: "กรุณาเลือก zone"})
      return flmZone
    },
    isEditor() {
      return this.$acl.check('editor')
    },
    isSuperEditor() {
      return this.$acl.check('super_editor')
    }
  },
  watch: {
    "$props.projectZonesLength": {
      // We have to move our method to a handler field
      handler (newValue, oldValue) {
        console.log('newValue: ', newValue)
        console.log('oldValue: ', oldValue)
        if (newValue < oldValue) {
          this.$attrs.value.location_flm = this.$attrs.value.location_flm.slice(0, newValue)
        }
      }
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    onChange(value) {
      if (value === 'all_day')
        this.$attrs.value.office_days = []
    },
  },
}
</script>
